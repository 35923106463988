@import "variables";

a.btn-social-icon {
  &:visited {
    color: #fff;
  }
  color: #fff;
  letter-spacing: 0 !important;
  i:nth-child(2) { display: none; }
}

.social-inverse-1 a:hover { background: rgba(255,255,255,0); } // 0.1 ?
.social-inverse-2 a {
  background: rgba(255,255,255,0);
  &:hover i:before { color: #fff; }
}
.social-transparent       a       { background: rgba(255,255,255, 0); }
.social-hover-transparent a:hover { background: rgba(255,255,255, 0); }
.social-hover-color-white a {
  i:before { transition: .25s all; }
  &:hover i:before { color: #fff; transition: .25s all; }
}

.social-round     a { border-radius: 200px !important; }
.social-rounded   a { border-radius: 3px   !important; }
.social-squared   a { border-radius: 0   !important; }
.social-border    a { border: 1px solid rgba(0, 0, 0, 0.25); }
.social-no-border a { border: 0; }
.social-coloured  a {
  i:before { transition: 0.25s; }
  background: rgba(255,255,255, 0);
  &:hover, &:focus { background: rgba(255,255,255, 0); }
}

.social-black-1 a {
  i:before { transition: color .25s; }
  &:hover i:before { color: #fff; }
  background: rgba(255,255,255, 0);
  i { color: #000; }
}

.social-black-2 a {
  background: rgba(255,255,255, 0);
  i { color: #000; }
}

.social-icons {
  display: inline-block;
  margin: -6px -4px;
  a {
    width: $social-medium-icon-size;
    height: $social-medium-icon-size;
    i {
      line-height: $social-medium-icon-size;
      font-size: $social-medium-font-size;
    }

    border-radius: 0;
    border: 0;
    margin: 5px 3px;
  }
  i { transition: 0.25s all; }
  &.footer-block {
    margin: 0;
  }
}

.social-huge a {
  width: $social-huge-icon-size !important;
  height: $social-huge-icon-size !important;
  i {
    line-height: $social-huge-icon-size !important;
    font-size: $social-huge-font-size !important;
  }
}
.social-large a {
  width: $social-large-icon-size !important;
  height: $social-large-icon-size !important;
  i {
    line-height: $social-large-icon-size !important;
    font-size: $social-large-font-size !important;
  }
}
.social-lmedium a {
  width: $social-lmedium-icon-size !important;
  height: $social-lmedium-icon-size !important;
  i {
    line-height: $social-lmedium-icon-size !important;
    font-size: $social-lmedium-font-size !important;
  }
}
.social-smedium a {
  width: $social-smedium-icon-size !important;
  height: $social-smedium-icon-size !important;
  i {
    line-height: $social-smedium-icon-size !important;
    font-size: $social-smedium-font-size !important;
  }
}
.social-small a {
  width: $social-small-icon-size !important;
  height: $social-small-icon-size !important;
  i {
    line-height: $social-small-icon-size !important;
    font-size: $social-small-font-size !important;
  }
}
.social-tiny a {
  width: $social-tiny-icon-size !important;
  height: $social-tiny-icon-size !important;
  i {
    line-height: $social-tiny-icon-size !important;
    font-size: $social-tiny-font-size !important;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .social-small a {
    width: $social-smedium-icon-size !important;
    height: $social-smedium-icon-size !important;
    i {
      line-height: $social-smedium-icon-size !important;
      font-size: $social-smedium-font-size !important;
    }
  }
  .social-tiny a {
    width: $social-small-icon-size !important;
    height: $social-small-icon-size !important;
    i {
      line-height: $social-small-icon-size !important;
      font-size: $social-small-font-size !important;
    }
  }
}

// social icons effects:
%social-anim  {
  width: 100%;
  height: 100%;
  text-align: center;
  display: inline-block;
}

%transparent-bg { background-color: rgba(255,255,255,0); }

// from down to up
.social-anim-1a  {
  > a:hover {
    i:first-child  { transform: translateY(-100%); }
    i:nth-child(2) { transform: translateY(0%); }
  }
  a i:nth-child(2) {
    @extend %social-anim;
    @extend %transparent-bg;
    transform: translateY(100%);
  }
}


// from up to down
.social-anim-1b {
  > a:hover {
    i:first-child  { transform: translateY(100%); }
    i:nth-child(2) { transform: translateY(0%); }
  }
  a i:nth-child(2) {
    @extend %social-anim;
    @extend %transparent-bg;
    transform: translateY(-100%);
  }
}

// from left to right
.social-anim-1c {
  > a:hover {
    i:first-child  { transform: translateX(100%); }
    i:nth-child(2) { transform: translateX(0%); }
  }
  a i:nth-child(2) {
    @extend %social-anim;
    @extend %transparent-bg;
    transform: translateX(-100%);
  }
}

// from right to left
.social-anim-1d {
  a:hover {
    i:first-child  { transform: translateX(-100%); }
    i:nth-child(2) { transform: translateX(0%); }
  }
  a i:nth-child(2) {
    @extend %social-anim;
    @extend %transparent-bg;
    transform: translateX(100%);
  }
}

// from down to up
.social-anim-2a {
  a.btn:hover { background-color: rgba(255,255,255,0); }
  a i:nth-child(2) {
    @extend %social-anim;
    transform: translateY(101%); // 100% will cause small issue
    &:before { color: #fff; }
  }
  a:hover {
    i:first-child  { transform: translateY(-100%); }
    i:nth-child(2) { transform: translateY(0%); }
  }
}

// from up to down
.social-anim-2b {
  a.btn:hover { background-color: rgba(255,255,255,0); }
  a i:nth-child(2) {
    @extend %social-anim;
    transform: translateY(-101%); // 100% will cause small issue
    &:before { color: #fff; }
  }
  a:hover {
    i:first-child  { transform: translateY(100%); }
    i:nth-child(2) { transform: translateY(0%); }
  }
}

// from left to right
.social-anim-2c {
  a.btn:hover { background-color: rgba(255,255,255,0); }
  a i:nth-child(2) {
    @extend %social-anim;
    transform: translateX(-101%); // 100% lefts vertical line on left side for some reason
    &:before { color: #fff; }
  }
  a:hover {
    i:first-child  { transform: translateX(100%); }
    i:nth-child(2) { transform: translateX(0%); }
  }
}

// from right to left
.social-anim-2d {
  a.btn:hover { background-color: rgba(255,255,255,0); }
  a i:nth-child(2) {
    @extend %social-anim;
    transform: translateX(101%); // 100% lefts vertical line on left side for some reason
    &:before { color: #fff; }
  }
  a:hover {
    i:first-child  { transform: translateX(-100%); }
    i:nth-child(2) { transform: translateX(0%); }
  }
}

// .social-anim-3 is designed in bootstrap-social.css
.social-anim-3a, .social-anim-3b {
  a:hover { @extend %transparent-bg; }
  a i:first-child:before { transition: box-shadow 0.3s; }
}


%social-anim-4-line {
  display: inline-block;
  position: absolute;
  left: 0;
  height: 0;
  width: 100%;
  content: "";
  transition: all 0.25s;
}

.social-anim-4a {
  a:hover i:first-child {
    top: -5px;
    &:after { height: 5px; }
  }
  a i:first-child:after {
    @extend %social-anim-4-line;
    bottom: 0;
  }
}

.social-anim-4b {
  a:hover i:first-child {
    top: 5px;
    &:after { height: 5px; }
  }
  a i:first-child:after {
    @extend %social-anim-4-line;
    top: -5px;
  }
}

%social-anim-5 {
  a.btn {
    perspective: 200px;
    overflow: visible;
    border: 0;
  }
  a i:first-child:before {
    @extend %social-anim;
    opacity: 1;
  }
  a i:before {
    line-height: inherit !important;
    font-size: inherit !important;
  }
  a i:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s;
    &:before { @extend %social-anim; }
  }

  a:hover {
    @extend %transparent-bg;
    i:first-child { opacity: 0; }
    i:nth-child(2) {
      opacity: 1;
      transform: rotateX(0deg) !important;
    }
  }
}

.social-anim-5a {
  @extend %social-anim-5;

  a i:nth-child(2)    { transform: translateY(25px) rotateX(-90deg); }
  a:hover i:first-child { transform: translateY(-25px) rotateX(90deg); }

  &.social-huge {
    a i:nth-child(2)      { transform: translateY(50px) rotateX(-90deg); }
    a:hover i:first-child { transform: translateY(-50px) rotateX(90deg); }}
  &.social-big {
    a i:nth-child(2)      { transform: translateY(38px) rotateX(-90deg); }
    a:hover i:first-child { transform: translateY(-38px) rotateX(90deg); }}
  &.social-small {
    a i:nth-child(2)      { transform: translateY(13px) rotateX(-90deg); }
    a:hover i:first-child { transform: translateY(-13px) rotateX(90deg); }}
}

.social-anim-5b {
  @extend %social-anim-5;

  a i:nth-child(2)      { transform: translateY(-25px) rotateX(90deg); }
  a:hover i:first-child { transform: translateY(25px) rotateX(-90deg); }

  &.social-huge {
    a i:nth-child(2)      { transform: translateY(-50px) rotateX(90deg); }
    a:hover i:first-child { transform: translateY(50px) rotateX(-90deg); }}
  &.social-big {
    a i:nth-child(2)      { transform: translateY(-38px) rotateX(90deg); }
    a:hover i:first-child { transform: translateY(38px) rotateX(-90deg); }}
  &.social-small {
    a i:nth-child(2)      { transform: translateY(-13px) rotateX(90deg); }
    a:hover i:first-child { transform: translateY(13px) rotateX(-90deg); }}
}

.social-fixed {
  position: fixed;
  top: calc(50% - 385px/2);
  width: inherit;
  z-index: 300;
  a { clear: both; }
}

.social-fixed-right {
  right: 0;
  a {
    float: right;
    margin-right: 10px;
  }
}

.social-fixed-left a {
  float: left;
  margin-left: 10px;
}

.social-fixed-bottom {
  bottom: 0;
  top: inherit;
  right: 0;
  left: 0;
  background: rgba(0,0,0,.1);
  margin: 0;
  a {
    float: none;
    margin: 0;
  }
}

div[class*="social-icons-fw"] {
  width: 100%;
  padding: 35px;
}

.social-icons-fw-theme,
 .social-icons-fw-image {
  a.btn {
    background: transparent;
    i { transition: none; }
    &:hover {
      color: rgba(255,255,255,.65);
    }
  }
}

.social-icons-fw-image {
  padding: 70px 0 !important;
  position: relative;
  h3 {
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    text-transform: uppercase;
    font-family: $oswald;
    letter-spacing: 1px;
    &:first-child { top: 22px; }
  }
  .social-fw-inner {
    background: rgba(0,0,0,.5);
    + h3 { bottom: 13px; }
  }
}
