.portfolio {
  /* needed for parallax */
  position: relative;
  width: 100%;
  &.portfolio-no-modify .gallery .gallery-item {
    height: auto;
  }
}

.portfolio-bg {
  background-size: cover;
}

.masonry-mixed {
  &.gallery .gallery-item {
    margin: 0;
  }
}
@media screen and (min-width: $screen-md) {
  .masonry-one-cell { height: 200px; }
  .masonry-margin-25 { margin-right: 25%; }
  .masonry-margin-50 { margin-right: 50%; }
  .masonry-big { height: 400px !important; }
}


.cart-success {
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -20;
}

.shop-item-info .add-to-cart-button {
  bottom: -6px;
  opacity: 0;
  transition: opacity .25s;
  background: transparent;
  input {
    color: rgba(0, 0, 0, 0.75) !important;
  }
  a {
    i {
      color: rgba(0,0,0,.75);
    }
    &:hover i {
      color: #000;
    }
  }
  .button-less,
  .button-more {
    color: rgba(0,0,0,.75);
    &:hover {
      color: #000;
    }
  }
}

.shop-item-description-button {
  top: -42px;
  position: relative;
  left: -100px;
  font-size: 15px;
  display: inline-block;
  opacity: 0;
  transition: opacity .25s;
  > a {
    color: #333;
    .fa-stack-1x {
      top: 2px;
    }
    &:hover {
      color: #111;
    }
    > span {
      pointer-events: none;
    }
  }
}

.shop-item-description {
  font-size: 14px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 30px 50px;
  font-family: $montserrat;
  font-weight: 500;
  background: rgba(255,255,255,.9);
  z-index: 20;
  color: #000;
  display: none;
  button {
    display: block;
    margin: auto;
    margin-top: 20px;
  }
  span {
    font-weight: bold;
  }
  hr { border-color: #999; }
}

.pswp-shop-item-description {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.85);
  color: #eee;
  padding: 50px 50px 30px 50px;
  font-family: $montserrat;
  font-size: 14px;
  display: none;
  line-height: 1.7;
  margin: auto;
  right: 0;
  z-index: 50;
  button {
    margin-top: 20px;
  }
  hr { border-color: #444; }
}

.event-parallax .gallery-cell .gallery-item {
  height: 600px;
   > a {
    pointer-events: none;
    cursor: default;
    > img { height: 600px; width: 100%; }
  }
  .shop-link {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    a {
      display: inline-block;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 900;
      pointer-events: all;
      position: relative;
      top: 28px;
      border-bottom: 1px solid rgba(0,0,0,.3);
      i {
        margin-left: 4px;
      }
    }
  }
  .event-name {
    margin-bottom: 10px;
  }
  .event-details {
    font-family: $montserrat;
    font-weight: 500;
    font-size: 22px;
    .event-date { margin-right: 20px; }
  }
  .text {
    padding: 25px 0 60px;
  }
}

.shop-parallax .gallery-cell .gallery-item {
  height: 600px;
   > a {
    pointer-events: none;
    cursor: default;
    > img { height: 600px; }
  }
  .text {
    span {
      font-family: $lato;
      font-weight: 600;
    }
    & ~ a {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 900;
      pointer-events: all;
    }
  }
  .shop-link {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    a {
      display: inline-block;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 900;
      pointer-events: all;
      position: relative;
      top: 7px;
    }
  }
}

.shop-checkout {
  .list-group-item-heading {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
    padding: 6px 12px;
    margin: 0;
    letter-spacing: .25px;
  }
}

.shop-footer {
  background: rgba(0, 0, 0, 0.05);
  padding-top: 42px;
}

.shop-checkout,
.shop-footer {
  .icon {
    position: absolute;
    cursor: default;
    i {
      font-size: 3rem;
    }
  }
  .text {
    margin-bottom: 0;
    padding-left: 60px;
  }
  .title {
    margin-bottom: 10px;
    padding-left: 60px;
    font-family: $montserrat;
    font-weight: 600;
    font-size: 16px;
  }
}



.pswp__caption {
  .add-to-cart-button {
    bottom: 14px;
    input {
      color: #999 !important;
    }
    a i {
      color: #999;
    }
  }
  .shop-item-size a {
    color: rgba(255, 255, 255, 0.75) !important;
  }
  .shop-item-colors,
  .shop-item-size {
    > a {
      font-weight: 700;
      &:hover,
      &:focus,
      &.active {
        border: 2px solid #bbb;
        color: #bbb !important;
      }
    }
  }
}

.gallery-icon.add-to-cart-button {
  right: 15px;
  margin-right: auto;
  margin-left: auto;
  left: 220px;
  position: absolute;
  input[type="text"] {
    box-shadow: none !important;
    position: absolute;
    top: -28px;
    width: 28px;
    left: 8px;
    background: transparent !important;
    border: 0;
    font-size: 16px;
    pointer-events: none;
    text-align: center;
    font-family: $oswald;
    padding: 0;
  }
  .button-less,
  .button-more {
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    transition: color .25s;
    z-index: 10;
  }
  .button-less {
    top: -12px;
    left: -2px;
  }
  .button-more {
    top: -12px;
    left: 34px;
  }
}

.shop-item,
.pswp__caption {
  .gallery-icon {

    width: 50px;
    line-height: 50px;
    height: 50px;
    &.trigger-image {
      font-size: 22px;
      &:hover a { color: #000; }
    }
    &.add-to-cart-button {
      font-size: 40px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      > a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        > i {
          pointer-events: none;
        }
      }
    }
    a {
      position: relative;
      left: 0;
      opacity: 1;
      height: 50px;
      line-height: 50px;
      transition: none;
      width: 50px;
      i {
        transition: all 0.25s;
      }

    }
  }
  .shop-item-description-button {
    margin-right: auto;
    margin-left: auto;
    display: inline-block;
    width: 35px;
  }
}

.shop-item {
  height: 450px !important;
  transition: box-shadow .25s;
  border-radius: 3px;
  .gallery-item-icons {
    height: 280px !important;
    background: transparent !important;
    transition: opacity .25s !important;
  }
  > a {
    height: 280px;
    position: relative;
    margin-top: 10px;
    > img {
      height: 100%;
      width: auto !important;
      position: absolute !important;
      top: -100%;
      bottom: -100%;
      left: -100%;
      right: -100%;
      margin: auto;
    }
  }
  &:hover {
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.5);
    .gallery-icon,
    .shop-item-description-button {
      opacity: 1;
    }
  }
}

.shop-item-info,
.pswp__caption {
  .title {
    font-size: 22px;
    margin-bottom: 5px;
    color: #ccc;
    font-family: $roboto;
    font-weight: 400;
    line-height: 1.1;
    display: inline-block;
    &:hover { color: #eee; }
  }
  .sale {
    position: absolute;
    width: 100%;
    right: 27px;
    font-size: 13px;
    vertical-align: initial;
    color: #555;
    text-decoration: line-through;
    z-index: -5;
    ~ .price {
      margin-left: 54px;
    }
  }
  .sale-badge {
    position: absolute;
    margin-left: 25px;
    width: 60px;
    font-size: 18px;
    letter-spacing: .5px;
    transform: rotate(-15deg);
    height: 60px;
    line-height: 60px;
    border-radius: 200px;
    color: white;
    font-family: $oswald;
    text-shadow: 1px 1px 1px rgba(0,0,0,.35);
    &.hot-badge { background: $red; }
    &.new-badge { background: $green; }
  }
  .price {
    margin-bottom: 10px;
    font-family: $lato;
    font-weight: 400;
    font-size: 20px;
    color: #bbb;
  }
}

.shop-item-info {
  position: absolute;
  top: 280px;
  left: 0;
  height: 145px;
  right: 0;
  .title {
    margin-top: 15px;
    color: #222;
    margin-bottom: 0;
    &:hover { color: #000; }
  }
  .price {
    color: #111;
  }
  .sale {
    color: #bbb;
  }
  .sale-badge {
    top: -50px;
    margin-left: 30px;
  }

}

.shop-item-colors {
  display: inline-block;
}

.shop-item-colors,
.minicart-details-color {
  .red-color     { background: $red;    }
  .green-color   { background: $green;  }
  .blue-color    { background: $blue;   }
  .pink-color    { background: $pink;   }
  .yellow-color  { background: $yellow; }
  .black-color   { background: $black;  }
  .white-color   { background: white;   }
  .teal-color    { background: $teal;   }
  .purple-color  { background: $purple; }
  .l-blue-color  { background: $light_blue; }
  .l-green-color { background: $light_green; }
  .cyan-color    { background: $cyan; }
}

.shop-item-size {
  position: relative;
  top: -3px;
}

.shop-item-colors,
.shop-item-size {
  height: 24px;
  > a {
    color: #444 !important;
    width: 24px;
    height: 24px;
    font-family: $raleway;
    border-radius: 50%;
    display: inline-block !important;
    line-height: 21px;
    border: 2px solid rgba(0, 0, 0, 0.35);
    font-size: 10px;
    font-weight: 800;
    font-style: italic;
    padding-right: 1.5px;
    margin: auto 1px;
    &:hover,
    &:focus,
    &.active {
      border: 2px solid #222;
      color: #222 !important;
    }
  }
}

.gallery .gallery-item {
  height: 200px;
  overflow: hidden;
  img {
    display: block;
    position: relative;
    width: 100%;
  }
  a {
    display: block;
    overflow: hidden;
  }
  > a > .figcaption {
    pointer-events: none;
  }
}

.gallery-item.gallery-item-large {
  height: 300px;
}

.gallery-item.gallery-item-huge {
  height: 400px;
}

.portfolio-hover-scale-in {
  figure > a > img {
    transition: transform .4s;
  }
  figure:hover > a > img {
    transform: scale(1.2);
  }
}

.portfolio-hover-scale-out {
  figure > a  > img {
    transition: transform .4s;
    transform: scale(1.2);
  }
  figure:hover > a > img {
    transform: scale(1);
  }
}

.portfolio-hover-ellipse .figcaption .button {
  position: absolute !important;
  top: -36px !important;
  width: 98% !important;
  left: 1%;
  height: 125px;
  margin-top: 0 !important;
  i { display: none; }
}

.portfolio-parallax .gallery-item a {
  img {
    height: 400px;
    width: 100%;
  }
}

.gallery-item-botom-wrapper {
  position: absolute;
  bottom: 15px;
  width: 100%;
  left: 0;
}

.gallery-icon {
  display: inline-block;
  line-height: 40px;
  width: 40px;
  height: 40px;
  font-size: 16px;
  margin: auto 2px;
  text-align: center;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 200px;
  a { color: #222 !important; }
}

.gallery-cell {
  padding: 15px;
  .text-wrapper {
    height: 100%;
    top: 0;
    position: absolute;
    width: 100%;
    display: table;
    .text-inner {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      .text {
        width: 100%;
        background: rgba(255,255,255,.75);
        padding: 15px 0 50px;
        font-size: 28px;
        font-family: $raleway;
        text-transform: uppercase;
      }
    }
  }
}
.col-lg-15.gallery-cell {
  padding: 3px;
  .gallery-item-title {
    font-size: 16px;
    font-weight: 600;
  }
  .outer-block {
    padding: 15px 5px;
  }
}

.portfolio-no-padding {
  .gallery-cell {
    padding: 0 !important;
    .gallery-item {
      margin: 0;
    }
  }
  .outer-block {
    padding: 15px 5px 20px 5px !important;
  }
}

.portfolio-small-padding {
  .gallery-cell {
    padding: 3px;
  }
}

.lightbox.portfolio-grid figure .figcaption button {
  height: 100%;
  width: 100%;
  margin-top: initial;
  color: #bbb !important;
}

.portfolio-grid {

  .outer-block {
    padding: 15px 5px 0 5px;
    span {
      display: inline-block;
      color: #888;
    }
  }
  .gallery-item-title {
    color: #222;
    text-transform: none;
    font-size: 20px;
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom: 5px;

    max-height: 22px;
    overflow: hidden;
  }

  figure {
    margin: 0;
    position: relative;
    .figcaption {
      text-align: center;
      span {
        display: block;
        color: #fff;
        font-style: italic;
        &:before {
          content: '';
          display: block;
          border-bottom: 1px solid rgba(255,255,255,.3);
          width: 50px;
          margin: 6px auto 5px;
        }
      }
      button {
        margin-top: 35px;
      }
    }
  }
}

.project-switcher {
  text-align: center;
  font-size: 30px;
  margin: 15px auto;
  > a {
    width: 70px;
    display: inline-block;
  }
  .grid {
    font-size: 25px;
  }
}

.single-project.single-project-gallery .lightbox .gallery-cell {
  height: 240px;
}

@media screen and (max-width: $screen-md-max) {
  .single-project.single-project-gallery .lightbox .gallery-cell {
    height: 200px;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .single-project.single-project-gallery .lightbox .gallery-cell {
    height: 230px;
  }
}

.single-project-title {
  font-weight: 500;
}

.single-project-video video {
  width: 100%;
}

.single-project-image {
  height: 490px;
  overflow: hidden;
  position: relative;
  .single-project-fw-title {
    top: 245px;
  }
  img {
    width: 100%;
  }
}

.single-project {
  position: relative;
  .icon-box .icon-wrapper .icon {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
  .icon-box-1-col .icon-box-content {
      margin-left: 90px;
    .heading {
      margin-bottom: .5rem;
    }
  }
  .wide-device-parallax {
    height: 475px;
    background-size: cover;
  }
  .tall-device-parallax {
    height: 690px;
    background-size: cover;
  }
  .heading-alt-2 {
    margin-bottom: 0;
    &:after {
      display: none;
    }
  }
}

.single-project-carousel-extended { max-height: 550px !important; }
.single-project-image-parallax { height: 550px !important; }
.single-project-video-extended {
  max-height: 550px;
  overflow: hidden;
  position: relative;
  .video-title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    color: #fff;
    font-size: 40px;
    font-family: $raleway;
    font-weight: 300;
    i.fa {
      margin-right: 5px;
      font-size: 35px;
    }
  }
  img {
    width: 100%;
  }
}

.single-project-fw-title {
  position: absolute;
  width: 100%;
  top: 275px;
  text-align: center;
  padding: 20px;
  font-family: $raleway;
  font-size: 44px;
  color: #151515;
  transform: translateY(-50%);
  span {
    border-bottom: 1px solid rgba(51, 51, 51, 0.65);
    position: relative;
    padding: 20px 30px;
    border-top: 1px solid rgba(51, 51, 51, 0.65);
  }
  &.alt {
    background: rgba(255,255,255,.65);
    font-weight: 500;
    font-size: 40px;
    padding: 40px 20px;
    span {
      padding: 15px 50px;
      border-color: rgba(51, 51, 51, 0.45);
    }
  }
}

.related-projects-title {
  font-weight: 500;
  font-size: 19px;
  text-transform: uppercase;
  position: relative;
  margin: auto;
  display: inline-block;
  &:before {
    content: '';
    border-bottom: 1px solid #ccc;
    position: absolute;
    right: calc(100% + 25px);
    width: 150px;
    bottom: 9px;
  }
  &:after {
    content: '';
    border-bottom: 1px solid #ccc;
    position: absolute;
    left: calc(100% + 25px);
    width: 150px;
    bottom: 9px;
  }
}

.related-carousel-itself {
  overflow: visible !important;
}

.single-project-carousel {
  max-height: 490px;
  overflow: hidden;
}

.single-project-table {
  list-style: none;
  padding-left: 0;
  line-height: 2;
  font-size: 14px;
  li {
    span {
      i { width: 20px; }
      width: 140px;
      display: inline-block;
      font-weight: 600;
    }
  }
}

.single-project-share {
  .social-icons {
    float: right;
    a {
      margin: 0 3px;
    }
  }
  > span {
    font-weight: 600;
    display: inline-block;
  }
}

@media screen and (max-width: $screen-tn-max) {
  .single-project-share .social-icons {
    margin: 15px 0 0 0;
  }
  .single-project-table li span,
  .single-project-share > span {
    width: 120px;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .single-project-share .social-icons {
    float: none;
  }
  .single-project-share > span {
    width: 140px;
  }
}


.gallery-cell-slider {
  .slick-dots {
    position: absolute;
    top: 0;
    z-index: 19;
    height: 0;
    text-align: right;
    right: 5px;
    pointer-events: none;
    li {
      pointer-events: all;
      margin: 0;
      width: 15px;
      &.slick-active button {
        background: rgba(0, 0, 0, 0.6);
      }
    }
    button {
      padding: 0;
      border-radius: 200px;
      width: 10px;
      height: 10px;
      background: rgba(0,0,0,.2);
      transition: background .25s, border .25s;
      &:before { color: transparent !important; }
      &:hover {
        background: rgba(255,255,255,.2) !important;
      }
    }
  }
  figure:hover .slick-dots {
    li {
      &.slick-active button {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

.gallery-tempholder {
  height: 0 !important;
  visibility: hidden;
}

.portfolio-button-drop figure {
  .figcaption button {
    position: relative;
    top: -10px;
    transition: top .4s, background .25s, color .25s, opacity .25s;
  }
  &:hover .figcaption button {
    top: 0;
  }
}

.portfolio-grid .figcaption {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  button {
    text-align: center;
    display: inline-block;
    i {
      position: relative;
      font-size: 17px;
    }
  }
}

.figcaption > .figcaption-inner {
  padding: 0 10px 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.portfolio-title-overlay,
.pswp {
  .gallery-item-title {
    margin: 0;
    padding: 0;
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

/* simple fade animation */
.portfolio-animation-std {
  .gallery-item {
    .figcaption {
      width: 100%;
      height: 100%;
      opacity: 0;
      overflow: visible;
      transition: all 0.4s ease-in-out;
      &:after { opacity: 0; }
    }
    &:hover .figcaption { opacity: 1; }
  }
}


%portfolio-animation-1 {
  figure { overflow: hidden; }
  .figcaption {
    height: 100%;
    width: 100%;
    opacity: 0;
    text-align: center;
    backface-visibility: hidden;
    transition: transform 0.3s, opacity 0.3s;
    span { display: block; }
    h3 { margin: 10px auto; }
  }
}

.gallery-item-icons {
  display: table;
  /* :) */
  z-index: 5;
  .gallery-item-title {
    font-size: 16px;
    margin-bottom: 10px;
    color: #ddd;
    font-weight: 500;
  }
}

.gallery-item-icons-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px 0;
}

.portfolio-animation-1a {
  @extend %portfolio-animation-1;
  figure:hover .figcaption {
    opacity: 1;
    transform: translate(15px, -15px);
  }
}

.portfolio-animation-1b {
  @extend %portfolio-animation-1;
  figure:hover .figcaption {
    opacity: 1;
    transform: translate(15px, 15px);
  }
}

.portfolio-animation-1c {
  @extend %portfolio-animation-1;
  figure:hover .figcaption {
    opacity: 1;
    transform: translate(-15px, 15px);
  }
}

.portfolio-animation-1d {
  @extend %portfolio-animation-1;
  figure:hover .figcaption {
    opacity: 1;
    transform: translate(-15px, -15px);
  }
}

%portfolio-animation-2 {
  figure img {
    z-index: 10;
    transition: transform 0.4s;
  }
  .figcaption {
    height: 80px;
    width: 100%;
    top: auto;
  }
}

div[class*='portfolio-animation-2'] {
  .gallery-icon {
    height: 30px;
    line-height: 30px;
    width: 30px;
  }
}

.portfolio-animation-2a {
  @extend %portfolio-animation-2;
  .figcaption { bottom: 0; }
  figure:hover img { transform: translateY(-80px); }
  figure img { height: 100%; }
}

.portfolio-animation-2b {
  @extend %portfolio-animation-2;
  .figcaption { top: 0; }
  figure:hover img { transform: translateY(80px); }
}

%portfolio-animation-3 {
  figure {
    overflow: hidden;
    img { transition: transform 0.4s; }
  }
  .figcaption {
    height: 100px;
    width: 100%;
    top: auto;
    opacity: 0;
    transition: transform 0.4s, opacity 0.1s 0.3s;
  }
}

.portfolio-animation-3a {
  @extend %portfolio-animation-3;
  .figcaption {
    bottom: 0;
    transform: translateY(100%);
  }
  figure:hover {
    img { transform: translateY(-10%); }
    .figcaption {
      opacity: 1;
      transform: translateY(0px);
      transition: transform 0.4s, opacity 0.1s;
    }
  }
}

.portfolio-animation-3b {
  @extend %portfolio-animation-3;
  .figcaption {
    top: 0;
    transform: translateY(-100%);
  }
  figure:hover {
    img { transform: translateY(100px); }
    .figcaption {
      opacity: 1;
      transform: translateY(0px);
      transition: transform 0.4s, opacity 0.1s;
    }
  }
}

%portfolio-animation-4 {
  .gallery-cell {
    perspective: 1700px;
    perspective-origin: 0 50%;
  }
  figure {
    transform-style: preserve-3d;
    & > div {
      overflow: hidden;
    }
    img { transition: transform 0.4s; }
  }
  .figcaption {
    height: 100%;
    width: 50%;
    opacity: 0;
    transition: transform 0.4s, opacity 0.1s 0.3s;
  }
}

.portfolio-animation-4a {
  @extend %portfolio-animation-4;
  .figcaption {
    transform: rotateY(-90deg);
    transform-origin: 0 0;
  }
  figure:hover {
    img { transform: translateX(50%); }
    .figcaption {
      opacity: 1;
      transform: rotateY(0deg);
      transition: transform 0.4s, opacity 0.1s;
    }
  }
}

.portfolio-animation-4b {
  @extend %portfolio-animation-4;
  .figcaption {
    transform: rotateY(90deg);
    transform-origin: right 0;
    left: 50%;
  }
  figure:hover {
    img { transform: translateX(-50%); }
    .figcaption {
      opacity: 1;
      transform: rotateY(0deg);
      transition: transform 0.4s, opacity 0.1s;
    }
  }
}

%portfolio-animation-5 {
  figure img {
    z-index: 10;
    transition: all 0.4s;
  }
  .figcaption {
    height: 100%;
    width: 100%;
    opacity: 0;
    transform: scale(0.7);
    transition: all 0.4s;
  }
  figure:hover {
    img {
      transform: scale(0.4);
    }
    .figcaption {
      transform: scale(1);
      opacity: 1;
    }
  }
}

div[class*='portfolio-animation-5'] {
  .gallery-item-icons,
  .gallery-item-icons-cell {
    display: block;
  }
}

%gallery-item-icons-bottom {
  .gallery-item-icons-cell {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 5px;
    text-align: center;
  }
}

%gallery-item-icons-top {
  .gallery-item-icons-cell { margin-top: 10px; }
}

.portfolio-animation-5a {
  @extend %portfolio-animation-5;
  @extend %gallery-item-icons-top;
  img { transform-origin: center bottom; }
}

.portfolio-animation-5b {
  @extend %portfolio-animation-5;
  @extend %gallery-item-icons-bottom;
  img { transform-origin: left 0; }
}

.portfolio-animation-5c {
  @extend %portfolio-animation-5;
  @extend %gallery-item-icons-bottom;
  img { transform-origin: center top; }
}

.portfolio-animation-5d {
  @extend %portfolio-animation-5;
  @extend %gallery-item-icons-bottom;
  img { transform-origin: right 0; }
}

.portfolio-animation-5e {
  @extend %portfolio-animation-5;
  @extend %gallery-item-icons-top;
  img { transform-origin: right; }
}

.portfolio-animation-5f {
  @extend %portfolio-animation-5;
  @extend %gallery-item-icons-top;
  img { transform-origin: left; }
}

.portfolio-overlay-1 .figcaption {
  background-image: url("/img/overlays/01.png");
}

.portfolio-overlay-2 .figcaption {
  background-image: url("/img/overlays/02.png");
}

.portfolio-overlay-3 .figcaption {
  background-image: url("/img/overlays/03.png");
}

.portfolio-overlay-4 .figcaption {
  background-image: url("/img/overlays/04.png");
}

.portfolio-overlay-5 .figcaption {
  background-image: url("/img/overlays/05.png");
}

.portfolio-overlay-6 .figcaption {
  background-image: url("/img/overlays/06.png");
}

.portfolio-overlay-7 .figcaption {
  background-image: url("/img/overlays/07.png");
}

.portfolio-overlay-8 .figcaption {
  background-image: url("/img/overlays/08.png");
}

.portfolio-overlay-9 .figcaption {
  background-image: url("/img/overlays/09.png");
}


.portfolio-filter .button {
  text-transform: uppercase;
  margin: 2px auto;
  transition: color .25s !important;
}

.portfolio-filter-wrapper {
  > .button-group, > .button {
    vertical-align: middle;
  }
  > .btn-group .btn, > .btn {
    padding: 10px 20px;
    transition: all .25s;
    margin-left: 0 !important;
    border-right: 0;
    transform: translateZ(0);
    &:hover, &:focus {
      border-color: #ccc;
      background: #eee;
    }
    &.active, &:active {
      border-color: #ccc;
      color: #000 !important;
    }
    &:last-child {
      border-right: 1px solid #ccc;
    }
  }
}

.button-shuffle {
  border: 1px solid #ccc;
  background: #fff;
  color: #656565;
}

@media screen and (max-width: $screen-sm-max) {
  .portfolio-filter {
    margin: 0 !important;
    button {
      width: 200px;
      float: none !important;
      border-radius: 3px !important;
      border: 1px solid #eee !important;
      margin: 4px !important;
    }
    &.button-group button {
      border-radius: 200px !important;
      margin: 2px !important;
      line-height: 32px !important;
    }
    & ~ .button-shuffle {
      margin-top: 2px;
    }
  }
}

.portfolio-sort-wrapper {
  margin: 10px auto;
  /* selectpicker */
  div.portfolio-sort {
    width: 350px !important;
    max-width: 100%;
    height: 45px;
    button {
      height: 100%;
    }
  }
  /* default select */
  select {
    width: 350px;
    margin: 0 auto;
    max-width: 100%;
    height: 45px;
  }
}

.portfolio-search-wrapper {
  margin: 10px auto;
}

.portfolio-search {
  height: 45px;
  width: 350px;
  max-width: 100%;
  margin: 0 auto;
}

.portfolio-filter {
  button {
    transition: all 0.25s;
    font-size: 13px;
    &.active {
      box-shadow: none;
    }
    &:hover { transition: all 0.25s; }
    &:active { box-shadow: none; }
  }
}

.btn-theme {
  background: #fff;
  &:hover {
    color: #fff;
  }
}

.portfolio-alt-filling .gallery-item {
  > a { height: 100%; }
  img {
    width: auto;
    height: 100% !important;
  }
  .slick-list {
    height: 100%;
    .slick-track {
      height: 100%;
      img {
        width: auto !important;
        position: absolute !important;
        left: 0 !important;
      }
    }
  }
  .gallery-tempholder {
    position: absolute;
  }
}


.portfolio-masonry {
  .gallery-cell:nth-child(1)  figure { height: $masonry-1;  }
  .gallery-cell:nth-child(2)  figure { height: $masonry-2;  }
  .gallery-cell:nth-child(3)  figure { height: $masonry-3;  }
  .gallery-cell:nth-child(4)  figure { height: $masonry-4;  }
  .gallery-cell:nth-child(5)  figure { height: $masonry-5;  }
  .gallery-cell:nth-child(6)  figure { height: $masonry-6;  }
  .gallery-cell:nth-child(7)  figure { height: $masonry-7;  }
  .gallery-cell:nth-child(8)  figure { height: $masonry-8;  }
  .gallery-cell:nth-child(9)  figure { height: $masonry-9;  }
  .gallery-cell:nth-child(10) figure { height: $masonry-10; }
  .gallery-cell:nth-child(11) figure { height: $masonry-11; }
  .gallery-cell:nth-child(12) figure { height: $masonry-12; }
  .gallery-cell:nth-child(13) figure { height: $masonry-13; }
  .gallery-cell:nth-child(14) figure { height: $masonry-14; }
  .gallery-cell:nth-child(15) figure { height: $masonry-15; }
  .gallery-cell:nth-child(16) figure { height: $masonry-16; }
  .gallery-cell:nth-child(17) figure { height: $masonry-17; }
  .gallery-cell:nth-child(18) figure { height: $masonry-18; }
  .gallery-cell:nth-child(19) figure { height: $masonry-19; }
  .gallery-cell:nth-child(20) figure { height: $masonry-20; }
}


/* photoswipe */

.pswp__caption__center {
  text-align: center !important;
  font-size: 15px !important;
  button { display: none; }
  .gallery-icon {
    display: none;
    &.add-to-cart-button {
      display: inline-block;
      background: transparent;
      right: -28px;
      color: #999;
      a:hover i,
      i:hover {
        color: #ddd;
      }
    }
  }
  .shop-item-description-button {
    position: absolute;
    opacity: 1;
    bottom: 23px;
    top: initial;
    right: 115px;
    transition: color .25s;
    > a {
      color: #999;
      display: inline-block;
      &:hover {
        color: #bbb;
      }
    }
  }
  .trigger-image {
    display: none;
  }
}

.pswp__img {
  transition: none;
}


.pswp__caption__center { display: table; }

.pswp__button--share,
.pswp--supports-fs .pswp__button--fs,
.pswp--zoom-allowed .pswp__button--zoom {
  display: none;
}


/* video block styles */
.pswp__zoom-wrap {
  text-align: center;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}
.video-wrapper {
  line-height: 0;
  width: 100%;
  max-width: 900px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}
.video-inner {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  width: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  video {
    width: 100% !important;
    height: auto !important;
  }
}

.simple-gallery { border: 2px solid transparent; }

.simple-gallery-cell-2-col { width: 50%;
            &.double-width { width: 100%; } }

.simple-gallery-cell-3-col { width: 33.33%;
            &.double-width { width: 66.66%; } }

.simple-gallery-cell-4-col { width: 25%;
            &.double-width { width: 50%; } }

.simple-gallery-cell-5-col { width: 20%;
            &.double-width { width: 40%; } }

.simple-gallery-cell-6-col { width: 16.66%;
            &.double-width { width: 33.33%; } }

.simple-gallery-cell {
  float: left;
  height: 250px;
  border: 2px solid transparent;
  overflow: hidden;
  figure {
    height: inherit;
  }
  img {
    height: 100%;
    width: auto !important;
  }
  &:not(.cell-square).double-width {
    height: 500px !important;
  }
}

.simple-gallery-cell.small-height {
  height: 200px;
  &:not(.cell-square).double-width {
    height: 400px !important;
  }
}



@media screen and (min-width: $screen-md) {
  .simple-gallery-cell.simple-gallery-cell-2-col:not(.cell-square):not(.portfolio-alt-filling) img {
    width: 100% !important;
    height: auto;
  }
}

@media screen and (min-width: $screen-lg) and (max-width: 1700px) {
  .gallery-cell.col-lg-15 {
    .shop-item-description-button {
      left: -90px;
    }
    .gallery-icon.add-to-cart-button {
      left: 185px;
    }
  }
}

@media screen and (min-width: $screen-lg) and (max-width: 1600px) {
  .gallery-cell.col-lg-15 {
    .shop-item-description {
      padding: 25px 30px;
      font-size: 13px;
    }
  }

}

@media screen and (min-width: 1365px) and (max-width: 1500px) {
  .gallery-cell.col-xlg-2 {
    .shop-item-description-button {
      left: -88px;
    }
    .gallery-icon.add-to-cart-button {
      left: 172px;
    }
    .shop-item-description {
      padding: 20px 25px;
      font-size: 13px;
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .gallery-cell.col-xs-12 {
    padding: 15px 0;
  }
  .portfolio-carousel .gallery > .slick-list,
  .products-carousel .gallery > .slick-list {
    margin: auto 0;
  }
  .simple-gallery-cell {
    width: 100% !important;
  }
}