@import "variables";

.PPMiniCart {
  position: fixed;
  top: 50px;
  z-index: 50000;
  transition: top .5s ease-in-out;
  form {
    position: relative;
    width: 350px;
    padding: 20px 0 15px 0;
    background: #fff;
    box-shadow: 1px 0 0 #ddd, -1px 0 0 #ddd, 0 -1px 0 #ddd, 0 15px 40px 10px rgba(0, 0, 0, 0.1);
    &.minicart-empty {
      padding-bottom: 10px;
      font-size: 16px;
      ul {
        display: none;
      }
    }
  }
  ul {
    margin: 20px 0 15px 0;
    padding: 10px 25px;
    list-style-type: none;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    max-height: 385px;
    overflow-y: scroll;
  }
  .shopping-cart-title {
    font-size: 16px;
    letter-spacing: .5px;
    font-family: $montserrat;
    margin-left: 25px;
    font-weight: 500;
  }
  .minicart-closer {
    float: right;
    font-size: 16px;
    margin-right: 25px;
  }
  .edit-cart {
    float: right;
    margin-right: 30px;
    font-size: 16px;
  }
  .minicart-item {
    padding: 15px 0;
    &:first-child {
      padding-top: 5px;
    }
    &:last-child {
      padding-bottom: 5px;
    }
    &+.minicart-item {
      border-top: 1px solid #eee;
    }
  }
  .minicart-details-name {
    float: left;
  }
  .minicart-details-img {
    height: 77px;
    float: left;
    border: 1px solid #eee;
    padding: 5px;
    background: #fafafa;
    border-radius: 1px;
    max-width: 80px;
    .minicart-img {
      height: 100%;
      max-width: 100%;
      width: initial;
    }
  }
  .minicart-details-size {
    float: right;
  }
  .minicart-item-description {
    padding-left: 85px;
    position: relative;
    font-family: $montserrat;
    font-weight: 500;
    font-size: 14px;
  }
  .minicart-price:before {
    content: "$";
  }
  .minicart-details-quantity {
    float: left;
    margin-top: 5px;
  }
  .minicart-details-remove {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .minicart-details-price {
    float: left;
    width: 100%;
    margin-top: 3px;
  }
  .minicart-footer {
    padding: 0 25px;
  }
  .minicart-attributes {
    margin: 0;
    padding: 0;
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: #999;
    font-size: 12px;
    line-height: 22px;
    li {
      display: inline;
      &:after {
        content: ",";
      }
      &:last-child:after {
        content: "";
      }
    }
  }
  .minicart-quantity {
    width: 40px;
    height: 26px;
    font-size: 13px;
    padding: 0 7px;
    border-radius: 2px;
    border: 1px solid #ccc;
    transition: border linear 0.2s, box-shadow linear 0.2s;
  }
  .minicart-remove {
    width: 20px;
    height: 20px;
    font-size: 14px;
    background: transparent;
    line-height: 1;
    font-family: 'fontawesome';
    text-shadow: none;
    color: rgba(0, 0, 0, 0.9) !important;
    &:active {
      box-shadow: none;
    }
    &:hover, &:focus {
      color: $red !important;
    }
  }
  .minicart-submit {
    font-size: 12px;
    float: right;
    letter-spacing: 1px;
  }
  .minicart-subtotal {
    float: left;
    line-height: 40px;
    font-family: $lato;
    font-size: 17px;
    font-weight: 700;
  }
}

.PPModalCart {
  z-index: 1000000;
  .modal-dialog {
    max-width: none;
    width: initial;
    max-height: none;
  }
  .modal-footer a {
    left: 25px;
  }
  form {
    position: relative;
    padding: 15px;
  }
  .minicart-empty ul {
    display: none;
  }
  .minicart-closer {
    display: none;
  }

  .minicart-empty-text {
    font-family: $raleway;
    font-weight: 400;
  }
}

.PPModalCart,
.tab-pane-cart,
.shopping-cart,
.wishlist,
.checkout-cart {
  ul {
    clear: both;
    padding: 0 15px;
    list-style-type: none;
    border: 2px solid #ddd;
    border-radius: 2px;
    box-shadow: none;
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .cart-rows {
    padding: 0 15px;
    & > div {
      text-align: center;
      font-weight: 600;
      padding-bottom: 10px;
      padding-top: 20px;
      color: #888;
      &:first-child {
        text-align: left;
        padding-left: 0;
      }
    }
  }

  .minicart-item {
    clear: left;
    height: 60px;
    line-height: 60px;
    margin-bottom: 15px;
    min-height: 25px;
    white-space: nowrap;
    position: relative;
    & + .minicart-item {
      border-top: 1px solid #f2f2f2;
    }
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .minicart-details-name {
    padding: 0;
    height: inherit;
    .minicart-name {
      font-size: 15px;
      line-height: 75px;
      font-weight: 600;
    }
  }
  .minicart-details-quantity {
    padding: 0;
    user-select: none;
    z-index: 10;
    i {
      cursor: pointer;
      display: inline-block;
      font-style: normal;
      font-size: 20px;
      position: relative;
      top: -2px;
      width: 21px;
      z-index: 10;
      transition: .25s all;
      height: 40px;
      text-align: center;
      vertical-align: middle;
      font-family: $raleway;
      line-height: 40px;
      border: 1px solid #ccc;
      &:first-child {
        right: -3px;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-right: 0 !important;
        line-height: 36px;
      }
      &:nth-child(3) {
        top: 18px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-left: 0 !important;
        position: absolute;
      }
    }
  }
  .minicart-details-remove {
    button {
        top: 30px;
        position: absolute;
        text-shadow: none;
        left: 25px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        background: transparent;
        color: rgba(0, 0, 0, 0.9);
        font: normal normal normal 16px/1 FontAwesome;
        transition: color .25s;
      &:hover, &:focus {
        color: $red !important;
      }
      &:active {
        box-shadow: none;
      }
    }
  }
  .minicart-details-img {
    left: 15px;
    top: 7.5px;
    height: inherit;
    img {
      height: 100%;
      position: absolute;
      top: -100%;
      bottom: -100%;
      left: -100%;
      right: -100%;
      margin: auto;
    }
  }
  .minicart-attributes {
    margin: 0;
    padding: 0;
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: #999;
    font-size: 12px;
    line-height: 22px;
    li {
      display: inline;
      &:after {
        content: ",";
      }
      &:last-child:after {
        content: "";
      }
    }
  }
  input[type="text"].minicart-quantity {
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    line-height: 40px;
    border: 1px solid #ccc !important;
    box-shadow: none !important;
    transition: border linear 0.2s, box-shadow linear 0.2s;
    margin-top: 17.5px;
    font-size: 15px;
    background: transparent;
    &:focus {
      outline: 0;
      box-shadow: none !important;
    }
  }

  .minicart-details-price,
  .minicart-details-size,
  .minicart-details-unit {
    text-align: center;
  }

  .minicart-size,
  .minicart-price {
    line-height: 75px;
    font-size: 15px;
  }
  .minicart-footer {
    text-align: right;
  }
  .minicart-subtotal {
    font-weight: 400;
    margin-bottom: 20px;
    font-size: 24px;
  }
}

.tab-pane-cart .minicart-details-quantity i:nth-child(3) {
  top: 17px;
}

.checkout-cart {
  .minicart-details-quantity {
    text-align: center;
    line-height: 75px;
    font-size: 15px;
  }
}

.minicart-details-desc {
  padding: 0;
  height: inherit;
  overflow: hidden;
}

.minicart-desc {
  line-height: 75px;
}

.shop-coupon {
  & > input {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 400;
    font-family: $oswald;
    text-transform: uppercase;
    vertical-align: bottom !important;
  }
  .small {
    margin-top: 8px;
  }
}

.shop-checkout {
  .left-title {
    padding-top: 11px;
  }
  .right-title {
    letter-spacing: 0;
    margin-bottom: 11px;
  }
  .accordion {
    .panel-body {
      padding: 20px 0 15px 0;
    }
    .panel {
      margin-bottom: 20px;
    }
  }
  .different-address,
  .create-account-checkout {
    .panel-heading { transition: border .25s; }
    .panel {
      transition: margin .25s;
      .panel-body { transition: padding .25s; }
    }
    .acc-open {
      .panel-heading { border-bottom: 1px solid transparent; }
      .panel-body { padding-bottom: 0; }
    }
    .panel-body {
      margin-top: -1px !important;
    }
  }
  .totals { margin-bottom: 35px; }
  .final-total { font-weight: bold; }
}

.ship-row {
  .ship-input {
    font-size: 15px;
    height: 36px;
  }
  .select2-container--default .select2-selection--single {
    font-size: 15px;
    height: 36px;
  }
  .title { margin-bottom: 2rem; }
  .totals { margin-bottom: 5px; }
  .final-total {
    font-weight: bold;
    border-bottom: 1px solid #ddd;
  }
}

.ship-table.table > tbody > tr > th {
  padding: 14px 8px;
}
.ship-table.table > tbody > tr > td {
  vertical-align: middle;
}

.shop-single-product {
  .nav-tabs.nav {
    > li > a {
      padding: 10px 30px;
    }
    + .tab-content .tab-pane {
      padding: 25px 30px;
      p { padding: 0 0 15px 0; }
      table.table.table-reflow td,
      table.table.table-reflow th {
        border: 0 !important;
      }
    }
  }
  .review-body {
    padding: 15px 0;
    padding: 30px 0;
    border-bottom: 1px solid #ddd;
    &:first-child {
      padding-top: 10px;
    }
  }
  .review-heading {
    font-family: $lato;
    font-size: 19px;
    margin-bottom: 10px;
  }
  .review-rating {
    margin-bottom: 15px;
  }
  .review-author,
  .review-date {
    letter-spacing: .25px;
  }
  .review-author {
    font-weight: 600;
  }
  .add-review {
    margin-top: 30px;
  }
  .add-review-title {
    margin-bottom: 15px;
  }
  .add-review button[type='submit'] {
    margin-top: 15px;
  }
}

.shop-single-product-info {
  .product-title {
    font-weight: 400;
    font-size: 36px;
    display: inline-block;
    margin-top: 4px;
  }
  .product-price {
    float: right;
    line-height: 1;
    font-size: 25px;
    font-weight: 600;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 10px;
  }
  .product-rating {
    margin-top: 10px;
    .rateit {
      display: inline-block !important;
      top: 3px;
    }
    > a {
      margin-left: 17px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      font-family: $montserrat;
    }
  }
  .product-description {
    margin-top: 10px;
    width: calc(100% - 120px);
    display: inline-block;
    > p {
      font-family: $roboto;
    }
  }
  .product-additional {
    float: right;
    text-align: right;
    letter-spacing: .25px;
    font-family: $roboto;
    > div {
      padding: 15px 0;
      border-top: 1px solid #ddd;
      &:first-child {
        border-top: 0;
      }
    }
    .stock {
      color: $green;
      text-transform: uppercase;
    }
  }
  .product-swatches {
    margin: 30px 0 20px 0;
  }
  .product-swatch {
    position: relative;
    margin: 10px 0;
    > span {
      font-weight: 600;
      font-family: $montserrat;
    }
    > div {
      display: inline-block;
    }
  }
  .shop-item-size {
    display: inline-block;
    top: 0;
    text-align: center;
  }
  .shop-item-size,
  .shop-item-colors {
    position: absolute;
    left: 55px;
  }
  .product-sub-buttons {
    margin-top: 40px;
    > a {
      margin-right: 25px;
      font-weight: 600;
      font-family: $montserrat;
      font-size: 13px;
      > .icon {
        font-size: 20px;
        vertical-align: bottom;
      }
    }
  }
  .product-share-buttons {
    margin-top: 20px;
    span {
      font-family: $montserrat;
      vertical-align: middle;
      margin-right: 10px;
      font-weight: 500;
    }
  }
  .add-to-cart-block {
    > span {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding: 9px;
      font-weight: 500;
      font-family: $montserrat;
    }
  }
  .product-quantity-wrapper {
    display: inline-block;
    margin: 0 15px;
    i {
      cursor: pointer;
      display: inline-block;
      font-style: normal;
      font-size: 20px;
      position: relative;
      top: -2px;
      width: 21px;
      z-index: 10;
      transition: .25s all;
      height: 40px;
      text-align: center;
      vertical-align: middle;
      font-family: $raleway;
      line-height: 40px;
      border: 1px solid #ccc;
      &:first-child {
        right: -3px;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-right: 0 !important;
        line-height: 36px;
      }
      &:nth-child(3) {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-left: 0 !important;
        left: -3px;
      }
    }
    input.product-quantity {
      width: 40px;
      height: 40px;
      position: relative;
      text-align: center;
      line-height: 40px;
      border: 1px solid #ccc !important;
      box-shadow: none !important;
      transition: border linear 0.2s, box-shadow linear 0.2s;
      margin-top: 17.5px;
      font-size: 15px;
      background: transparent;
    }
  }
}

.shop-single-sidebar-brand img {
  width: 100%;
  cursor: pointer;
}

.shop-single-sidebar-iconboxes {
  .iconbox-wrapper {
    margin-top: 10px;
    .title {
      font-size: 16px;
      font-family: $oswald;
      text-transform: uppercase;
      >.fa {
        font-size: 18px;
        margin-right: 5px;
      }
    }
    .text {
      line-height: 1.7;
      color: #888;
      font-family: $roboto;
      margin-top: 5px;
    }
    &:after {
      width: 50px;
      height: 1px;
      background: #ddd;
      content: '';
      display: block;
      margin-top: 10px;
    }
    &:last-child:after {
      display: none;
    }
  }
}

.related-products-title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 50px;
  span {
    font-size: 22px;
    border-bottom: 1px solid #ddd;
    padding: 10px 15px;
    border-top: 1px solid #ddd;
  }
}

.related-product-cell {
  display: table;
}

.related-product-image {
  float: left;
  width: 100px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  img {
    max-width: 100px;
    max-height: 100%;
  }
}

.related-product-description {
  display: table-cell;
  vertical-align: middle;
  width: 69%;
  position: relative;
  padding-left: 5px;
  .title {
    font-family: $raleway;
    font-weight: 600;
    font-size: 17px;
  }
  .sale {
    display: inline-block;
    color: #bbb;
    text-decoration: line-through;
    font-size: 13px;
  }
  .price {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
  }
  .rateit {
    margin-top: 5px;
    margin-bottom: 0;
  }
  .sale-badge {
    position: absolute;
    left: -40px;
    top: 40px;
    width: 35px;
    font-size: 12px;
    letter-spacing: .5px;
    transform: rotate(-15deg);
    height: 35px;
    line-height: 35px;
    border-radius: 200px;
    color: white;
    font-family: $oswald;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0,0,0,.35);
    &.hot-badge { background: $red; }
    &.new-badge { background: $green; }
  }
}

.shop-sidebar-title {
  text-transform: uppercase;
  font-size: 18.5px;
  font-family: $oswald;
  font-weight: 400;
  margin-bottom: 20px;
}

.shop-single-sidebar-item {
  margin-bottom: 40px;
  border-bottom: 1px solid #eee;
  padding-bottom: 40px !important;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
  }
}

.sidebar-subscribe-title {
  text-transform: uppercase;
  font-size: 20px;
  font-family: $oswald;
  letter-spacing: .5px;
  font-weight: 400;
  & + span {
    font-size: 13px;
    margin: 5px 0 15px 0;
    display: inline-block;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .PPModalCart {
    .minicart-details-quantity {
      position: relative;
      left: -16px;
    }
    .minicart-details-remove {
      position: relative;
      left: -5px;
    }
  }
  .cart-rows {
    div:nth-child(4) {
      position: relative;
      left: 16px;
    }
  }
  .shop-single-sidebar-brand img {
    max-height: 100px;
    width: inherit;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .PPModalCart, .tab-pane-cart, .shopping-cart, .checkout-cart {
    .minicart-item:last-child {
      margin-bottom: 166px !important;
    }
    .minicart-details-name {
      text-align: center;
      height: 75px !important;
    }
  }
  .PPModalCart {
    .wishlist .minicart-item:last-child {
      margin-bottom: 91px !important;
    }
    .minicart-details-quantity {
      position: relative;
      left: -11px;
      text-align: center;
    }
    .minicart-details-img {
      left: 0 !important;
      top: 15px !important;
    }
    .minicart-details-remove {
      left: -20px !important;
    }
  }
  .tab-pane-cart {
    .minicart-details-name,
    .minicart-details-quantity {
      text-align: center;
    }
    .minicart-details-quantity {
      left: -10px;
    }
    .minicart-details-img {
      left: 0;
    }
  }
  .cart-rows {
    display: none !important;
  }
  .wishlist {
    .minicart-item {
      height: initial;
      .minicart-details-img {
        height: 60px;
      }
    }
    .minicart-details-desc {
      overflow: visible;
      white-space: initial;
      height: auto;
    }
    .minicart-desc {
      line-height: initial;
      margin-top: 20px;
    }
    .minicart-details-name, .minicart-desc {
      text-align: center;
    }
  }
  .shopping-cart {
    .minicart-details-quantity {
      text-align: center;
      left: -11px;
    }
  }

  .shopping-cart, .checkout-cart {
    .minicart-details-name {
      text-align: center;
    }
    .minicart-details-img {
      left: 0;
    }
  }

}

@media screen and (max-width: $screen-tn-max) {
  .PPModalCart .minicart-empty-text { margin: 1rem auto; }
  .PPMiniCart {
    width: 100%;
    right: 0 !important;
    form { width: 100%; }
  }

  .shop-single-product-info .product-description {
    width: calc(100% - 90px);
  }
  .shop-single-product-info .add-to-cart-block .button {
    margin-top: 15px;
  }

}
