// footer styles
.footer {
  position: relative;
  background-color: #333;
  background-size: 100% auto;
  background-position: center top;
  color: #ddd;
  li { list-style-type: none; }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      margin-bottom: .5rem;
    }
  }
  a {
    &:focus,
    &:hover {
      color: #fff;
    }
    color: #ddd;
    &.btn { color: #fff; }
  }
  p { color: #ddd; }
   h1, h2, h3, h4, h5, h6,
  .h1,.h2,.h3,.h4,.h5,.h6 {
    font-weight: 500;
  }
  .feedback {
    .circle-img {
      height: 4em;
      img {
        max-width: 100%;
        height: 100%;
        margin: 0 auto;
      }
    }
    blockquote {
      font-size: 1rem;
      font-style: italic;
      margin: 0;
    }
    .feedback-text { font-family: $raleway; }
    .author { font-weight: 500; }
  }
  hr {
    border-top: 1px solid #656565;
  }
  .form-control {
    font-size: 14px;
  }
}

.footer-img-bg {
  background: url("/img/app/overlays/dark-3.png");
}

.footer-portfolio {
  a {
    display: inline-block;
    overflow: hidden;
    margin: 2px;
    border-radius: 3px;
    border: 2px solid rgba(0,0,0,.15);
    transition: border .25s;
    &:hover {
      border: 2px solid rgba(0,0,0,.5);
    }
    img {
      height: inherit;
      width: auto !important;
    }
  }
}

.footer-tags ul a {
  display: inline-block;
  border: 1px solid #bbb;
  padding: 5px 5px;
  text-transform: uppercase;
  font-size: 10px;
}

.footer-archive .archive-border li {
  border-bottom: 1px solid #444;
  padding-bottom: .5rem;
  &:last-child { border-bottom: 0 !important; }
}

.gallery-tiny-icons .footer-portfolio a {
  height: 50px;
  width: 50px;
}

.gallery-small-icons .footer-portfolio a {
  height: 75px;
  width: 75px;
}

.gallery-medium-icons .footer-portfolio a {
  height: 100px;
  width: 100px;
}

.title-logo-chunk {
  border-right: 1px solid #ddd;
}

.footer-title {
  text-transform: uppercase;
  margin-bottom: 2rem !important;
  letter-spacing: 1px;
}

.footer-title-small {
  text-transform: uppercase;
  margin-bottom: .75rem !important;
  letter-spacing: 1px;
}

.footer-special {
  color: #151515;
  background-size: cover;
  .footer-twitter {
    color: #151515;
  }
  hr { border-top: 1px solid rgba(0,0,0,.15) !important; }
  a:not(.btn) {
    &:focus,
    &:hover {
      color: black !important;
    }
    color: #151515;
  }
  .footer-block-news li {
    border-bottom: 1px solid rgba(0,0,0,.25) !important;
  }
  p, h4 {
    color: #333;
  }
}

.footer-tiny { min-height: $footer-tiny-height;
  .row:only-child { min-height: $footer-tiny-height; }
}

.footer-medium { min-height: $footer-medium-height;
  .row:only-child { min-height: $footer-medium-height; }
}

.footer-tall { min-height: $footer-tall-height;
  .row:only-child { min-height: $footer-tall-height; }
}


.footer-block-news {
  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    margin-bottom: 15px !important;
    padding-bottom: 15px;
    a {
      display: inline-block;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: 0 !important;
    }
  }
  .date {
    margin-top: 10px;
  }
  .news-img {
    overflow: hidden;
    float: left;
    width: 56px;
    height: 56px;
    border-radius: 200px;
    margin: 5px 1rem;
    img {
      height: inherit;
    }
  }
  .news-text {
    padding-left: 84px;
    display: block;
  }
}

.footer-block-news-alt {
  .news-img { float: right; }
  .news-text { padding-left: 0; padding-right: 77px; }
}

.footer-block-news-center {
  .news-img { float: none; display: block; margin: 10px auto; }
  .news-text { padding-left: 0; padding-right: 0; }
  .date { text-align: center; }
}

.footer-block-logo img {
  width: 100px;
}


.footer-subscribe-button {
  position: absolute;
  top: 0;
  right: -8px;
  padding: 0 15px;
}

.footer-search-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 15px;
}

.footer-block-twitter {
  li {
    margin-bottom: 13px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.footer-block-counter .counter {
  font-family: $lato;
}

.footer-block-counter-uppercase .footer-counter-wrapper {
  font-family: $lato;
}

.footer-block-counter-newline .counter { display: block; }

.footer-block-devices {
  position: relative;
  overflow: hidden;
  padding-bottom: 0 !important;
  max-height: 280px;
  .device-small-image img { position: relative; }
  img { width: 100%; }
}

.footer-block-2-col-tall-1st:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  border-right: 1px solid #555;
}

@media screen and (max-width: $screen-sm-max) {
  .footer-block-2-col-tall-1st {
    &:after { border-right: 0; }

    margin-bottom: 3rem;
  }
}

.footer-big-icon {
  font-size: 150px;
  text-align: center;
}

.footer-subtitle {
  margin-bottom: 0;
}


.footer-block {
  padding-top: 1em;
  padding-bottom: 1em;
}

.footer-block-social a { margin: 2px; }

.footer-block-social-desc {
  width: 100%;
  a.btn {
    float: left;
    margin-right: 7px;
  }
  a.text {
    width: initial !important;
    display: table-cell;
    vertical-align: middle;
  }
}

.footer-block-subscribe-form .has-feedback .form-control,
.footer-block-search .has-feedback .form-control {
  padding: 6px 12px;
}

.footer-block-search form button,
.footer-block-subscribe form button {
  right: 2px;
  padding: 0 15px;
  position: relative;
  z-index: 5;
}

.footer-twitter-title {
  margin-bottom: 10px;
}

.footer-block-contacts {
  position: relative;
  z-index: 0;
  li > i {
    width: 15px;
    text-align: center;
  }
  &:after {
    content: '';
    background: url("/img/app/map.png") no-repeat;
    background-size: contain;
    background-position: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    opacity: .2;
    z-index: -1;
  }
}

.footer-2-col-tall .footer-block {
  padding: 0;
}

.footer-fixed-bottom {
  z-index: 500;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer-fixed-bottom-bg {
  z-index: -101;
  display: none;
}

.footer-fixed-white-helper {
  position: absolute;
  top: 0;
  z-index: -100;
  width: 100%;
  background: white;
}

@media screen and (max-width: $screen-xs-max) {
  .footer-block-devices .device-small-image img {
    top: 55px;
  }
  .footer-block-subscribe-form {
    max-width: 350px;
  }
  .footer-fixed-bottom {
    display: none;
  }
}

@media screen and (min-width: $screen-sm) {
  .footer-block-subscribe-form {
    width: 350px;
    max-width: 100%;
  }
}
