// icon styles, used in "icon-box" blocks

@keyframes spin {
  from { transform: rotate(0deg);   }
  to   { transform: rotate(360deg); }
}

@keyframes rightLeft {
  49% { transform: translate(100%); }
  50% { opacity: 0; transform: translate(-100%); }
  51% { opacity: 1; }
}

@keyframes leftRight {
  49% { transform: translate(-100%); }
  50% { opacity: 0; transform: translate(100%); }
  51% { opacity: 1; }
}

@keyframes topBottom {
  49% { transform: translateY(-100%); }
  50% { opacity: 0; transform: translateY(100%); }
  51% { opacity: 1; }
}

@keyframes bottomTop {
  49% { transform: translateY(100%); }
  50% { opacity: 0; transform: translateY(-100%); }
  51% { opacity: 1; }
}

@keyframes sonarEffect {
  0%  { opacity: 0.3; }
  40% { opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.5);
    transform: scale(1.5); opacity: 0;
  }
}

@keyframes sonarEffect_black {
  0%  { opacity: 0.3; }
  40% { opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(68, 68, 68, 0.1), 0 0 10px 10px #444, 0 0 0 10px rgba(68, 68, 68, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(68, 68, 68, 0.1), 0 0 10px 10px #444, 0 0 0 10px rgba(68, 68, 68, 0.5);
    transform: scale(1.5); opacity: 0;
  }
}

%icon-anim-1 {
  .icon {
    transition: background 0.2s, color 0.2s;
    &:after {
      transition: transform 0.2s, opacity 0.2s;
      backface-visibility: hidden;
      transform: scale(.8);
      opacity: 0;
    }
  }
  
  &:hover {
    .icon:after {
      transform: scale(1);
      opacity: 1;
    }
  }
}

%icon-anim-2 {
  .icon {
    transition: color 0.3s;
    &:after {
      top: -2px;
      left: -2px;
      padding: 2px;
      z-index: -1;
      transition: transform 0.2s, opacity 0.2s;
    }
  }
}

%icon-anim-3 {
  .icon {
    transition: color 0.3s;
    &:after {
      top: -2px;
      left: -2px;
      padding: 2px;
      z-index: -1;
      transition: transform 0.2s, opacity 0.3s;
    }
  }
}

%icon-anim-4 {
  .icon {
    transition: box-shadow 0.2s;
    &:after {
      top: 0;
      left: 0;
      padding: 0;
      transition: transform 0.2s, opacity 0.2s;
    }
  }
}


%icon-anim-5 {
  .icon {
    transition: background 0.2s, color 0.2s;
    &:after {
      z-index: -1;
      opacity: 0;
    }
  }
}

%icon-anim-7 {
  .icon {
    overflow: hidden;
    transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    &:after {
      display: none;
    }
  }
}

%icon-anim-8 {
  .icon:after {
    padding: 0;
    z-index: 10;
  }
}