/* styles for "icon-box" block */

.icon-box-default {
  .icon-wrapper { float: left; }
  &.carousel .slick-slide[tabindex] {
    padding: 1rem;
    height: initial;
    opacity: 1;
    .icon-box-content {
      margin: 1.5rem 0 0 0;
    }
  }
  .slick-arrow {
    height: 2rem;
    width: 2rem;
    border-radius: 4px;
    &:before {
      font-size: 1rem;
    }
  }
}

.icon-box-content {
  margin-left: 85px;
  margin-bottom: 3rem;
  .heading {
    text-transform: uppercase;
    letter-spacing: .5px;
    margin-bottom: 10px;
    line-height: 1.3;
  }
  p { color: #888; }
}


.icon-box-default .icon-wrapper .icon {
  cursor: pointer;
  transition: all 0.25s;
  padding: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 2rem;
}

.icon-box {
  &.icon-box-big-icon {
    .icon-wrapper .icon {
      width: 3.25rem;
      height: 3.25rem;
      line-height: 3.25rem;
      font-size: 2.5rem;
    }
    .icon-box-content {
      margin-left: 95px;
    }
  }
  &.icon-box-small-icon {
    .icon-wrapper .icon {
      width: 1.6rem;
      height: 1.6rem;
      line-height: 1.6rem;
      font-size: 1.5rem;
    }
    .icon-box-content {
      margin-left: 75px;
    }
  }
}


.icon-box-default.icon-box-static .icon-wrapper .icon {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 3rem;
  padding-top: 0;
  padding-bottom: 3rem;
  &.small-icon {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 2rem;
  }
  &.big-icon {
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 4rem;
  }
} 

.icon-box-colored,
.icon-box-circle,
.icon-box-filled {
  .icon {
    width: 4rem;
    font-size: 3.5rem;
    height: 4rem;
    line-height: 4rem;
  }
}

.icon-box-single {
  .icon-wrapper {
    display: inline-block;
    i {
      padding: 14px;
    }
  }
}

.icon-box-colored .icon-wrapper {
  width: 100%;
  padding: 20px 0;
}

.icon-box-circle .icon-wrapper {
  width: 140px;
  padding: 26px 0 !important;
}

.icon-box-color-gray .icon {
  color: #aaa;
}

.icon-box-centered {
  .icon-wrapper {
    float: none;
    text-align: center;
    margin: 0;
  }
  .icon-box-content {
    margin: 1.5rem 0 3rem 0;
    text-align: center;
  }
}

.icon-box-right {
  .icon-wrapper {
    float: right;
    margin-right: 0;
    margin-left: 1rem;
  }
  .icon-box-content {
    margin-left: 0;
  }
}

.icon-box-wrapper-border {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 3rem 2rem 0 2rem;
}

.icon-box-media {
  .icon-box-wrapper-border {
    padding: 0;
  }
  .icon-box-content {
    margin: 2rem;
  }
  .img-wrapper {
    height: 200px;
    overflow: hidden;
    img {
      width: 100%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
  .video-wrapper {
    overflow: hidden;
    position: relative;
    height: 200px;
    iframe {
      width: 100%;
      min-height: 200px;
    }
  }
}

.icon-box-wrapper-bg {
  background: #eee;
}




/* styles for icons itself */

.icon {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  position: relative;
  z-index: 1;
  box-sizing: content-box;
  &:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    box-sizing: content-box;
  }
  &:before {
    display: block;
  }
  &.circle-block:after { border-radius: 200px; }
  &.rounded-block:after { border-radius: 3px; }
}

.icon-box-border-black {
  .icon { box-shadow: 0 0 0 2px #444; }
  &:not(.no-animation):hover .icon { box-shadow: none; }
}

.icon-box-bg-gray .icon {
  background: #eee;
}

.icon-box-bg-black .icon {
  background: #444;
  color: white;
}

/* icon animations: */

%icon-anim-1-colorful {
  .icon {
    background: rgba(255, 255, 255, 0.1);
    &:after {
      box-shadow: 0 0 0 3px #fff;
      top: -7px;
      left: -7px;
      padding: 7px;
    }
  }
  &:hover {
    .icon { background: #fff; }
  }
}

.icon-anim-1a {
  @extend %icon-anim-1;
  @extend %icon-anim-1-colorful;
}

.icon-anim-1b {
  @extend %icon-anim-1;
  @extend %icon-anim-1-colorful;
  .icon:after {
    transform: scale(1.2);
  }
}


%icon-anim-1-black {
  .icon:after {
    box-shadow: 0 0 0 2px #444;
    top: -3px;
    left: -3px;
    padding: 3px;
  }
  &:hover {
    .icon {
      background: #444 !important;
      color: white !important;
    }
  }
}

.icon-anim-1a-black {
  @extend %icon-anim-1;
  @extend %icon-anim-1-black;
}

.icon-anim-1b-black {
  @extend %icon-anim-1;
  @extend %icon-anim-1-black;
  .icon:after {
    transform: scale(1.15);
  }
}

// end of 1



%icon-anim-2-colorful {
  .icon {
    box-shadow: 0 0 0 3px #fff;
    &:after { background: #fff; }
  }
}

.icon-anim-2a {
  @extend %icon-anim-2;
  @extend %icon-anim-2-colorful;
  &:hover .icon:after {
    transform: scale(0.85);
  }
}

.icon-anim-2b {
  @extend %icon-anim-2;
  @extend %icon-anim-2-colorful;
  &:hover .icon {
    color: #fff;
    &:after {
      transform: scale(0);
      opacity: 0;
      transition: transform 0.4s, opacity 0.2s;
    }
  }
}

%icon-anim-2-black {
  .icon {
    box-shadow: 0 0 0 2px #444;
    color: white;
    &:after { background: #444; }
  }
}

.icon-anim-2a-black {
  @extend %icon-anim-2;
  @extend %icon-anim-2-black;
  &:hover .icon:after {
    transform: scale(0.85);
  }
}

.icon-anim-2b-black {
  @extend %icon-anim-2;
  @extend %icon-anim-2-black;
  &:hover .icon {
    color: #444;
    &:after {
      transform: scale(0);
      opacity: 0;
      transition: transform 0.4s, opacity 0.2s;
    }
  }
}

// end of 2

%icon-anim-3-colorful {
  .icon {
    box-shadow: 0 0 0 3px #fff;
    &:after { background: #fff; }
  }
}

.icon-anim-3a {
  @extend %icon-anim-3;
  @extend %icon-anim-3-colorful;
  &:hover .icon {
    color: #fff;
    &:after {
      transform: scale(1.3);
      opacity: 0;
    }
  }
}

.icon-anim-3b {
  @extend %icon-anim-3;
  @extend %icon-anim-3-colorful;
  .icon {
    color: #fff;
  }
  &:hover .icon {
    color: #000;
    &:after {
      transform: scale(1);
      opacity: 1;
    }
  }
  .icon:after {
    transform: scale(1.3);
    opacity: 0;
  }
}

%icon-anim-3-black {
  .icon {
    box-shadow: 0 0 0 2px #444;
    &:after { background: #444; }
  }
}

.icon-anim-3a-black {
  @extend %icon-anim-3;
  @extend %icon-anim-3-black;
  .icon { color: white; }
  &:hover .icon {
    color: #444;
    &:after {
      transform: scale(1.3);
      opacity: 0;
    }
  }
}

.icon-anim-3b-black {
  @extend %icon-anim-3;
  @extend %icon-anim-3-black;
  .icon {
    color: #444;
  }
  &:hover .icon {
    color: white;
    &:after {
      transform: scale(1);
      opacity: 1;
    }
  }
  .icon:after {
    transform: scale(1.3);
    opacity: 0;
  }
}

// end of 3

%icon-anim-4-colorful {
  .icon {
    &:after { box-shadow: 0 0 0 3px #fff; }
  }
}

.icon-anim-4a {
  @extend %icon-anim-4;
  @extend %icon-anim-4-colorful;
  &:hover .icon {
    box-shadow: 0 0 0 8px #fff;
    color: #fff;
    &:after {
      transform: scale(0.85);
      opacity: 0.5;
    }
  }
}

.icon-anim-4b {
  @extend %icon-anim-4;
  @extend %icon-anim-4-colorful;
  &:hover .icon {
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.4);
    color: #fff;
    &:after {
      transform: scale(0.85);
    }
  }
}

%icon-anim-4-black {
  .icon {
    &:after { box-shadow: 0 0 0 2px #444; }
  }
}

.icon-anim-4a-black {
  @extend %icon-anim-4;
  @extend %icon-anim-4-black;
  &:hover .icon {
    box-shadow: 0 0 0 6px #444;
    color: #444;
    &:after {
      transform: scale(0.85);
      opacity: 0.5;
    }
  }
}

.icon-anim-4b-black {
  @extend %icon-anim-4;
  @extend %icon-anim-4-black;
  &:hover .icon {
    box-shadow: 0 0 0 4px rgba(68, 68, 68, 0.4);
    color: #444;
    &:after {
      transform: scale(0.85);
    }
  }
}

// end of 4

%icon-anim-5-colorful {
  .icon {
    box-shadow: 0 0 0 3px #fff;
    &:after {
      top: -8px;
      left: -8px;
      padding: 8px;
    }
  }
  &:hover .icon {
    color: #fff;
  }
}

.icon-anim-5a {
  @extend %icon-anim-5;
  @extend %icon-anim-5-colorful;
  .icon {
    &:after {
      box-shadow: 0 0 0 #fff;
      transition: opacity 0.2s, box-shadow 0.2s;
    }
    &:before {
      transform: scale(0.8);
      transition: transform 0.2s, opacity 0.2s;
    }
  }
  
  &:hover .icon {
    &:after {
      opacity: 1;
      box-shadow: 3px 3px 0 #fff;
    }
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.icon-anim-5b {
  @extend %icon-anim-5;
  @extend %icon-anim-5-colorful;
  .icon {
    &:after {
      box-shadow: 3px 3px #fff;
      transform: rotate(-90deg);
      transition: opacity 0.2s, transform 0.2s;
    }
    &:before {
      transform: scale(0.8);
      transition: transform 0.2s, opacity 0.2s;
    }
  }
  
  &:hover .icon {
    &:after {
      opacity: 1;
      transform: rotate(0deg);
    }
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}

%icon-anim-5-black {
  .icon {
    box-shadow: 0 0 0 2px #444;
    &:after {
      top: -5px;
      left: -5px;
      padding: 5px;
    }
  }
  &:hover .icon {
    color: #444;
  }
}

.icon-anim-5a-black {
  @extend %icon-anim-5;
  @extend %icon-anim-5-black;
  .icon {
    &:after {
      box-shadow: 0 0 0 #444;
      transition: opacity 0.2s, box-shadow 0.2s;
    }
    &:before {
      transform: scale(0.8);
      transition: transform 0.2s, opacity 0.2s;
    }
  }
  
  &:hover .icon {
    &:after {
      opacity: 1;
      box-shadow: 2px 2px 0 #444;
    }
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.icon-anim-5b-black {
  @extend %icon-anim-5;
  @extend %icon-anim-5-black;
  .icon {
    &:after {
      box-shadow: 2px 2px #444;
      transform: rotate(-90deg);
      transition: opacity 0.2s, transform 0.2s;
    }
    &:before {
      transform: scale(0.8);
      transition: transform 0.2s, opacity 0.2s;
    }
  }
  
  &:hover .icon {
    &:after {
      opacity: 1;
      transform: rotate(0deg);
    }
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}

// end of 5

.icon-anim-6a {
  .icon {
    background: rgba(255, 255, 255, 0.3);
    transition: transform ease-out 0.1s, background 0.2s;
    &:after {
      top: 0;
      left: 0;
      padding: 0;
      z-index: -1;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
      opacity: 0;
      transform: scale(0.9);
    }
  }
  
  &:hover .icon {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(0.93);
    color: #fff;
    &:after {
      animation: sonarEffect 1.3s ease-out 75ms;
    }
  }
}

.icon-anim-6a-black {
  .icon {
    transition: transform ease-out 0.1s, background 0.2s;
    &:after {
      top: 0;
      left: 0;
      padding: 0;
      z-index: -1;
      box-shadow: 0 0 0 2px rgba(68, 68, 68, 0.1);
      opacity: 0;
      transform: scale(0.9);
    }
  }
  
  &:hover .icon {
    background: #444;
    transform: scale(0.93);
    color: #fff;
    &:after {
      animation: sonarEffect_black 1.3s ease-out 75ms;
    }
  }
}

%icon-anim-7-colorful {
  .icon {
    box-shadow: 0 0 0 3px #fff;
  }
  &:hover .icon {
    background: #fff;
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.3);
  }
}

.icon-anim-7a {
  @extend %icon-anim-7;
  @extend %icon-anim-7-colorful;
  &:hover .icon:before {
    animation: rightLeft 0.3s forwards;
  }
}

.icon-anim-7b {
  @extend %icon-anim-7;
  @extend %icon-anim-7-colorful;
  &:hover .icon:before {
    animation: leftRight 0.3s forwards;
  }
}

.icon-anim-7c {
  @extend %icon-anim-7;
  @extend %icon-anim-7-colorful;
  &:hover .icon:before {
    animation: topBottom 0.3s forwards;
  }
}

.icon-anim-7d {
  @extend %icon-anim-7;
  @extend %icon-anim-7-colorful;
  &:hover .icon:before {
    animation: bottomTop 0.3s forwards;
  }
}

%icon-anim-7-black {
  .icon {
    box-shadow: 0 0 0 2px #444;
  }
  &:hover .icon {
    color: white;
    background: #444;
    box-shadow: 0 0 0 5px rgba(68, 68, 68, 0.3);
  }
}

.icon-anim-7a-black {
  @extend %icon-anim-7;
  @extend %icon-anim-7-black;
  &:hover .icon:before {
    animation: rightLeft 0.3s forwards;
  }
}

.icon-anim-7b-black {
  @extend %icon-anim-7;
  @extend %icon-anim-7-black;
  &:hover .icon:before {
    animation: leftRight 0.3s forwards;
  }
}

.icon-anim-7c-black {
  @extend %icon-anim-7;
  @extend %icon-anim-7-black;
  &:hover .icon:before {
    animation: topBottom 0.3s forwards;
  }
}

.icon-anim-7d-black {
  @extend %icon-anim-7;
  @extend %icon-anim-7-black;
  &:hover .icon:before {
    animation: bottomTop 0.3s forwards;
  }
}

%icon-anim-8-colorful {
  .icon {
    box-shadow: 0 0 0 3px #fff;
    &:after {
      border: 3px dashed #fff;
      top: -3px;
      left: -3px;
    }
  }
  &:hover .icon {
    box-shadow: 0 0 0 0 transparent;
    color: #fff;
  }
}

.icon-anim-8a {
  @extend %icon-anim-8;
  @extend %icon-anim-8-colorful;
  .icon {
    transition: box-shadow 0.2s;
  }
}

.icon-anim-8b {
  @extend %icon-anim-8;
  @extend %icon-anim-8-colorful;
  &:hover .icon {
    transition: box-shadow 0.2s;
    &:after {
      animation: spin 9s linear infinite;
    }
  }
}

%icon-anim-8-black {
  .icon {
    box-shadow: 0 0 0 2px #444;
    &:after {
      border: 2px dashed #444;
      top: -2px;
      left: -2px;
    }
  }
  &:hover .icon {
    box-shadow: 0 0 0 0 transparent;
    color: #444;
  }
}

.icon-anim-8a-black {
  @extend %icon-anim-8;
  @extend %icon-anim-8-black;
  .icon {
    transition: box-shadow 0.2s;
  }
}

.icon-anim-8b-black {
  @extend %icon-anim-8;
  @extend %icon-anim-8-black;
  &:hover .icon {
    transition: box-shadow 0.2s;
    &:after {
      animation: spin 9s linear infinite;
    }
  }
}


/* styles for hover animation used in "feedback" block */


.img-icon {
  width: 100%;
  height: 100%;
  bottom: 100%;
  position: relative;
  transition: all 0.4s ease-in-out;
  display: table;
  .image-overlay {
    display: table-cell;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    transform: scale(0);
    vertical-align: middle;
    p {
      color: #fff;
      font-size: 12px;
      border-top: 1px solid rgba(255, 255, 255, 0.25);
      margin: 0 20px;
      a {
        display: block;
        color: rgba(255, 255, 255, 0.7);
        &:hover {
          color: white;
        }
      }
    }
    .img-hover-title {
      color: #fff;
      font-size: 16px;
    }
  }
  &:hover {
    .image-overlay {
      opacity: 1;
      transform: scale(1);
    }
  }
}


.img-icon-anim-1 {
  box-shadow: inset 0 0 0 16px rgba(255, 255, 255, 0.6), 0 1px 2px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: inset 0 0 0 0 #fff;
  }
}

/* icon anim 10 defined in color template */


@media screen and (max-width: $screen-sm-max) {
  .icon-box-media .img-wrapper,
  .icon-box-media .video-wrapper {
    height: 150px;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .icon-box-media .img-wrapper,
  .icon-box-media .video-wrapper {
    height: auto;
  }
}