.image-half-container {
  display: table;
  background-size: cover;
  height: 100%;
}

.image-half-solo-video iframe {
  height: 285px;
  width: 75%;
}

.image-half-info {
  display: table;
  height: 100%;
}

.image-half-icon {
  font-size: 6rem;
}

.image-half-icon-white {
  color: #eee;
  background: rgba(0,0,0,.5);
}

.image-half-video-container {
  height: 130px;
  a {
    background-size: cover;
    background-position: center;
    width: 20%;
    height: 100%;
    float: left;
  }
}

.image-half-big-video-container img {
  height: 160px;
}

.image-half-big-video {
  display: inline-block;
  width: 100%;
  padding-right: 15px;
  a {
    float: left;
    padding-right: 15px;
  }
}

.image-half-big-video-second {
  text-align: right;
  display: inline-block;
  width: 100%;
  padding-left: 15px;
  a {
    float: right;
    padding-left: 15px;
  }
}

.image-half-info-inner {
  display: table-cell;
  vertical-align: middle;
  padding-right: 15px;
  padding-left: 15px;
}

@media screen and (max-width: $screen-xs-max) {
  .image-half-solo-video iframe {
    width: 100%;
  }
  .image-half,
  .image-half .row:only-child {
    height: auto;
  }
  .image-half-container {
    height: 300px;
    width: 100%;
  }
  .image-half-info {
    width: 100%;
  }
}

@media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
  .image-half-big-video,
  .image-half-big-video-second {
    a {
      width: 50%;
      overflow: hidden;
      height: 160px;
      padding: 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    span {
      position: relative;
      width: calc(50% - 45px);
      display: inline-block;
    }
  }
  .image-half-big-video {
    padding-right: 0;
    span { left: 15px; }
  }
  .image-half-big-video-second {
    padding-left: 0;
    span { left: -15px; }
  }
}