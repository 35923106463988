.event-youtube,
.event-vimeo {
  iframe {
    width: 100%;
    height: 100%;
  }
}

.event-sidebar-title {
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-size: 16px;
  font-family: $raleway;
  font-weight: 600;
}

.event-sidebar-element {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}

.event-image {
  max-width: 220px;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  img {
    width: 100%;
  }
}

.event-info {
  height: 220px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  display: table !important;
  &:last-child {
    border-bottom: 0;
  }
}

.event-description {
  display: table-cell;
  vertical-align: middle;
  padding: 15px 0 15px 15px;
}

.event-place a {
  margin-left: 5px;
}

.event-buttons {
  .button {
    &:nth-child(1) {
      margin-right: 5px;
    }
  }
}

.event-place,
.event-date,
.event-time {
  & > i {
    margin-right: 5px;
    width: 20px;
    text-align: center;
  }
}

.event-map .map-canvas {
  min-height: initial;
}

.event-info-text {
  font-size: 15px;
}

.event-fullwidth {
  .event-info {
    width: 100%;
    padding: 25px;
    height: initial;
  }
  .event-image {
    width: 33%;
  }
}

.event-single-banner img {
  max-width: 100%;
}
.event-single-video video {
  max-width: 100%;
}

.event-single-list {
  margin-top: 8px;
}

.event-single-gallery .gallery-cell {
  width: 150px;
  max-height: 100px;
  overflow: hidden;
}

.event-sidebar-instagram.gallery-tiny-icons a img {
  width: 45px;
}

.events-title {
  font-weight: 600;
  span {
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    padding: 10px 30px;
  }
}


@media screen and (max-width: $screen-sm-max) {
  .event-info {
    border-bottom: 0;
  }
}

@media screen and (min-width: $screen-xs) and (max-width: $screen-xs-max) {
  .event-image {
    max-width: 185px;
  }
}

@media screen and (min-width: $screen-tn) and (max-width: $screen-tn-max) {
  .event-description {
    padding: 15px 0;
  }
}

@media screen and (max-width: $screen-tn-max) {
  .event-image {
    display: table-row;
  }
  .event-info {
    margin-bottom: 20px;
  }
  .events-title span {
    border: 0;
    padding: 0;
  }
}
