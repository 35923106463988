/* modal widow styles */

.modal-dialog {
  /* min-height: 500px; if set to 0% then anim is fade not slide */
  max-width: 350px;
  max-height: 90vh;
  margin: 15px auto;
  .modal-content {
    width: 100%;
    .modal-header {
      border: 0;
    }
    .close {
      top: -10px;
      left: 2px;
      position: relative;
      font-size: 40px;
      z-index: 20;
      transition: 0.25s;
      &:focus {
        outline: none;
      }
    }
    .modal-footer {
      border: 0;
      .button {
        bottom: 0;
        right: 0;
      }
    }
  }
}

/* register / login */

.register-modal,
.login-modal {
  .modal-body {
    padding-top: 5px;
    text-align: center;
  }
  .modal-divider-wrapper {
    margin: 10px;
  }
  .button-login,
  .button-register {
    width: 100%;
    margin-top: 30px;
  }
  .form-control {
    margin-bottom: .5rem;
  }
  .modal-title {
    position: relative;
    display: inline-block;
    right: -5px;
    margin-bottom: 0;
  }
}

.modal-social .btn {
  margin: auto 5px;
}


/* map modal */

.map-modal {
  .modal-dialog {
    height: 520px;
    width: 85%;
    max-width: none;
  }
  .modal-content {
    min-height: 100%;
  }
  .modal-title {
    display: inline-block;
  }
  .modal-body {
    width: 100%;
    position: absolute;
    top: 0;
    padding-top: 70px;
    padding-bottom: 70px;
    height: 100%;
  }
  .modal-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .map-modal-canvas {
    height: 100%;
    width: 100%;
  }
}

// contact modal

.contact-modal {
  .modal-dialog {
    max-width: 600px;
  }
}

/* video modal */

.video-modal {
  .modal-dialog {
    max-width: 800px;
  }
  .modal-body {
    height: 500px;
  }
  .modal-content {
    height: 100%;
  }
}

.video-modal-frame {
  padding-bottom: 50px;
  height: 100%;
  iframe {
    height: 100%;
    width: 100%;
  }
}

/* mail modal */

.email-modal {
  .modal-header span {
    white-space: nowrap;
  }
  .modal-dialog {
    width: 100%;
    max-width: 600px;
  }
  .mail-content {
    width: 100%;
    height: 100%;
    resize: none;
    border-radius: 4px;
    padding: 5px 10px;
  }
  .modal-body {
    padding-top: 0;
    height: 397px;
  }
  .mail-subject {
    margin-right: 10px;
    margin-left: 5px;
    width: 200px;
    display: inline-block;
    height: 28px;
    padding: 8px 10px;
    &:focus {
      color: black;
      outline: none;
    }
  }
}

/* custom css modal */

.custom-css-modal {
  .modal-dialog {
    width: 100%;
    max-width: 600px;
  }
  .css-content {
    width: 100%;
    height: 100%;
    resize: none;
    min-height: 350px;
    color: #000;
    font-family: monospace;
    background: #fff;
  }
  a.button.demo-dl-css-button {
    color: #111;
  }
}


/* phone modal */

.phone-modal {
  .modal-dialog {
    max-width: 360px;
  }
  .modal-footer {
    text-align: right;
    padding: 10px;
  }
  .modal-content {
    max-height: 400px;
  }
  .modal-body {
    display: table;
    width: 100%;
    height: 250px;
  }
  .phone-modal-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    i {
      font-size: 2em;
    }
    table {
      margin-left: auto;
      margin-right: auto;
      border-collapse: separate;
      border-spacing: 10px;
    }
  }
  .close {
    position: absolute;
    right: 15px;
  }
}

.event-info-modal {
  .modal-dialog {
    background: rgba(0,0,0,.6);
    border-radius: 6px;
  }
  .modal-body {
    padding: 40px;
  }
}

/* flickr gallery modal */

.flickr-gallery-modal {
  margin: 0 !important;
  top: 0 !important;
  .modal-dialog {
    width: auto;
    max-width: 750px;
  }
  .modal-body {
    text-align: center;
  }
  .modal-footer {
    text-align: center;
  }
  .img-wrapper {
    width: 100% !important;
    height: initial !important;
    vertical-align: middle;
    display: table-cell;
    img {
      width: inherit !important;
      height: inherit;
    }
  }
}


#flickr-gallery-modal-buttons .modal-body {
  height: 480px;
  display: table;
}

.flickr-gallery-modal-title {
  width: calc(100% - 22px) !important;
  margin-bottom: 0 !important;
}

.modal.fade .modal-dialog {
  transition: none;
  transform: none;
}

.modal-content {
  box-shadow: none;
  border: 0;
  border-radius: 3px;
}

.fancy-modal {
  .modal-backdrop.in { opacity: 0.85; }
  .modal-content {
    background: transparent !important;
    color: #ddd;
    a { color: #ddd; }
    a.link {
      color: #ddd;
      border-bottom: 1px solid #444;
      &:hover { border-color: #676767; }
    }
  }
  .modal-title,
  .flickr-gallery-modal-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 18px;
  }
  .mail-content {
    background: rgba(255,255,255,.05);
  }
  .modal-dialog {
    input { color: #bbb !important; }
      button.close {
      opacity: .85;
      color: #fff;
      width: 55px;
      height: 55px;
      border-radius: 3px;
      margin-top: 0;
      margin-right: 1px;
      font-size: 22px !important;
      top: -15px !important;
      text-shadow: none;
      background: rgba(0, 0, 0, 0.2);
      &:hover { opacity: 1; background: rgba(0,0,0,.5); }
    }
  }

  #flickr-gallery-modal-buttons .modal-body {
    height: 450px;
    padding-top: 0;
  }
  .flickr-gallery-modal .modal-footer {
    padding-top: 0;
  }
  .flickr-button-prev,
  .flickr-button-next {
    height: 65px;
    width: 65px;
    top: -15px;
    position: relative;
    background: transparent !important;
    font-size: 20px !important;
    opacity: 0.85;
    &:hover {
      background: rgba(0,0,0,.25) !important;
      opacity: 1;
    }
    &:active {
      text-shadow: none;
      color: #fff !important;
      box-shadow: none;
    }
  }
  .flickr-button-next {
    border-bottom-right-radius: 4px;
    left: -1px;
  }
  .flickr-button-prev {
    border-bottom-left-radius: 4px;
    left: 1px;
  }
  .PPModalCart {
    .modal-body {
      background: rgba(0,0,0,.75);
      border-radius: 3px;
    }
    button.close {
      position: relative;
      left: 16px;
      &:hover {
        opacity: 1;
        background: rgba(255, 255, 255, 0.25);
      }
    }
    ul {
      border: 1px solid #444;
    }
    .cart-rows {
      padding-top: 40px;
    }
    .minicart-details-remove button {
      color: #999;
    }
    .minicart-item + .minicart-item {
      border-top: 1px solid #333;
    }
    input[type="text"].minicart-quantity,
    .minicart-details-quantity i {
      border-color: #444 !important;
    }
    .minicart-quantity {
      color: #ddd !important;
    }
  }
}

.shop-modal {
  .modal-body {
    .title {
      line-height: 1.25;
      font-weight: 400;
      margin-bottom: 35px;
      i {
        font-family: $lato;
        font-size: 28px;
        font-weight: 600;
        font-style: normal;
      }
    }
    .content img {
      float: right;
      width: 100px;
      margin-left: 5px;
    }
  }
  .modal-footer {
    text-align: left;
    .button { letter-spacing: .5px; }
  }
  video {
    width: 100%;
  }
}

.shop-modal-subscribe {
  .modal-header button.close {
    top: 8px !important;
    position: absolute;
    right: 14px;
    left: auto;
  }
  .modal-body {
    input {
      margin-bottom: 50px;
      margin-top: 35px;
    }
    .help-block {
      position: absolute;
      top: 47px;
    }
  }
}

.shop-modal-video {
  .modal-header button.close {
    top: 8px !important;
    position: absolute;
    right: 15px;
    left: auto;
  }
  .heading {
    font-size: 3rem;
    letter-spacing: 1px;
  }
  .modal-dialog {
    max-width: 400px;
  }
  .title {
    margin-bottom: 30px;
  }
  .modal-footer {
    text-align: right;
  }
  video ~ p {
    font-size: 23px;
  }
}

.shop-modal-form {
  .heading {
    letter-spacing: 1px;
  }
  form {
    img {
      float: right;
      width: 73px;
    }
    input {
      max-width: 240px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .phone-modal .phone-modal-inner td > a {
    font-size: 25px;
  }
  .flickr-gallery-modal-title {
    font-size: 20px;
  }
}
