.twitter-typeahead {
  display: block !important; }

.tt-menu {
  width: 100%;
  margin: 4px 0;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1); }

.tt-suggestion {
  padding: 3px 20px;
  font-size: 14px;
  line-height: 20px; }
  .tt-suggestion:hover {
    cursor: pointer;
    color: #fff; }

.tt-suggestion.tt-cursor {
  color: #fff; }

/*# sourceMappingURL=typeahead.css.map */
