// #################
// ### instagram ###
// #################

#instafeed { display: inline-block; }
.instagram-block {
  a img { margin: 5px; }
  > [id^=instafeed] {
    margin: -5px;
  }
}

.gallery-tiny-icons {
  a img { width: 50px; }
  .likes {
    line-height: 50px;
    font-size: 10px;
  }
}
.gallery-small-icons {
  a img { width: 75px; }
  .likes {
    line-height: 75px;
    font-size: 15px;
  }
}
.gallery-medium-icons {
  a img { width: 100px; }
  .likes {
    line-height: 100px;
    font-size: 20px;
  }
}
.gallery-big-icons {
  a img { width: 125px; }
  .likes {
    line-height: 125px;
    font-size: 25px;
  }
}
.gallery-huge-icons {
  a img { width: 150px; }
  .likes {
    line-height: 150px;
    font-size: 30px;
  }
}

.instafeed-border {
  a {
    padding:5px;
    margin:10px;
    border:1px solid #e1e1e1;
    display:inline-block;
    border-radius: 1px;
    position:relative;
    &:hover .likes { opacity: 1; }
  }
  .likes {
    color: #ffffff;
    opacity: 0;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    transition: opacity 0.25s ease;
    position: absolute;
    top: 10px;
    bottom: 10px;
    right: 10px;
    left: 10px;
  }
}

// ###############
// ### twitter ###
// ###############

.twitter-block {
  max-width: 400px;
  margin: 0 auto;
  li {
    padding: 10px 0;
  }
  .twitter {
    padding: 0;
    list-style: none;
  }
}

// ################
// ### dribbble ###
// ################

.dribbble-shot-wrapper {
  display: inline-block;
  width: 200px;
  height: 150px;
  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    img {
      height: 100%;
    }
  }
}

.dribbble-shots-huge .dribbble-shot-wrapper {
  width: 333px;
  height: 250px;
}

.dribbble-shots-big .dribbble-shot-wrapper {
  width: 267px;
  height: 200px;
}

.dribbble-shots-small .dribbble-shot-wrapper {
  width: 133px;
  height: 100px;
}

.dribbble-shots-tiny .dribbble-shot-wrapper {
  width: 66px;
  height: 50px;
}

.dribbble-shots-padding li { padding: 5px; }

.flickr-gallery {
  .thumbnails {
    padding-left: 0;
    text-align: center;
    li a { border-radius: 1px; }
  }
  .span1 {
    margin: 0 10px;
    display: inline-block;
  }
  .pagination {
    display: block;
    margin: 0;
    text-align: center;
  }
  .pagination-prev { margin-right: 5px; }
  .btn { border-radius: 2px; }
}

.flickr-no-border .thumbnail { padding: 0; border: 0; }

.tumblr-post-wrapper iframe { margin: auto !important; }
