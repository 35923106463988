@import "variables";

.dark-theme {
  background-color: #222;
  color: $dark-theme-color;

  blockquote {
    border-left: 5px solid rgba(255, 255, 255, 0.1);
  }

  .button-inverse, .button-inverse-flat {
    background-color: #303030;
    border-color: #303030;
    &:hover, &:focus {
      background-color: #3c3c3c;
      border-color: #3c3c3c;
    }
  }

  .button-inverse:active,
  .button-inverse.active,
  .button-inverse.is-active,
  .button-inverse-flat:active,
  .button-inverse-flat.active,
  .button-inverse-flat.is-active {
    background-color: #222;
    border-color: #222;
    color: #090909;
  }



  .checkbox input:not([disabled]):not(:checked) ~ label:hover:before,
  .checkbox input:not([disabled]):not(:checked):hover ~ label:before,
  .radio input:not([disabled]):not(:checked) ~ label:hover:before,
  .radio input:not([disabled]):not(:checked):hover ~ label:before {
    background: rgba(255,255,255,.35);
    border: rgba(255,255,255,.35);
  }

  .checkbox input:not([disabled]) ~ label:hover:before,
  .checkbox input:not([disabled]):hover ~ label:before,
  .radio input:not([disabled]) ~ label:hover:before,
  .radio input:not([disabled]):hover ~ label:before {
    background: rgba(255,255,255,.35);
    border: rgba(255,255,255,.35);
  }

  .dropdown-menu-title {
    background: #222;
  }

  .floating-box {
    hr { border-top: 1px solid #eee; }
  }

  .minicart-empty-text {
    color: $dark-theme-color;
  }

  .icon-bar { background: $dark-theme-color; }
  .instafeed-border a { border: 1px solid #333; }

  .menu {
    .navbar {
      background-color: #222;
      li > a { color: #bbb; }
      .dropdown-menu { background-color: #222; }
      &.navbar-border {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    hr { border-top: 1px solid #303030; }
    .multi-column .row > div {
      border-right: 1px solid #303030;
    }
    .dropdown-menu {
      box-shadow: 1px 0 0 #333, -1px 0 0 #333, 0 -1px 0 #333, 0 15px 40px 10px rgba(0,0,0,.3);
    }
    .dropdown-menu-title {
      border-bottom: 1px dashed #303030;
      color: #ccc;
    }
    .rg-12-cell {
      border-right: 1px solid #262626 !important;
    }
  }

  .dropdown-img .row {
    background: url("/img/shop/sale-girl-dark.png") right top no-repeat;
    background-size: contain;
  }


  .navbar-collapse {
    box-shadow: none;
  }

  .gallery-cell .text-wrapper .text-inner .text {
    background: rgba(0, 0, 0, 0.75);
  }

  .event-parallax .gallery-cell .gallery-item .shop-link a {
    border-bottom: 1px solid rgba(255,255,255,.3);
  }

  .popover {
    border-color: #111;
    background-color: #111;
    &.bottom > .arrow:after { border-bottom-color: #111; }
    &.top > .arrow:after { border-top-color: #111; }
    &.left > .arrow:after { border-left-color: #111; }
    &.right > .arrow:after { border-right-color: #111; }
  }
  .popover-title   {
    background-color: #222;
    color: #eee;
    border-color: #111;
  }
  .popover-content { background-color: #222; }

  .navbar-transparent,
  .navbar-always-transparent {
    .nav > li {
      &.open > a { color: #eee !important; }
      > a,
      > a:hover,
      > a:focus {
        color: #eee !important;
      }
    }
    .logo-wrapper,
    .navbar-header-buttons > li > a,
    .navbar-header-buttons .navbar-single-icon a,
    .navbar-toggle { color: #eee !important; }
    .icon-bar { background: #eee; }

    // used for demo purposes
    &.solid-color.navbar.menu-navbar-collapse {
      nav ul.nav > li {
        &.open > a { color: #bbb !important; }
        > a,
        > a:hover,
        > a:focus {
          color: #bbb !important;
        }
      }
      div.navbar-header {
        .logo-wrapper,
        .navbar-header-buttons > li > a,
        .navbar-header-buttons .navbar-single-icon a,
        .navbar-toggle { color: #bbb !important; }
        .icon-bar { background: #bbb; }
      }
    }
    // ---
  }

  .navbar-transparent.navbar-solid {
    .nav > li {
      &.open > a { color: #bbb !important; }
      > a,
      > a:hover,
      > a:focus {
        color: #bbb !important;
      }
    }
    .logo-wrapper,
    .navbar-header-buttons > li > a,
    .navbar-header-buttons .navbar-single-icon a,
    .navbar-toggle { color: #bbb !important; }
    .icon-bar { background: #bbb; }
  }

  .navbar-transparent-inverse {
    .nav > li {
      &.open > a { color: #111 !important; }
      > a,
      > a:hover,
      > a:focus {
        color: #111 !important;
      }
    }

    .logo-wrapper,
    .navbar-header-buttons > li > a,
    .navbar-header-buttons .navbar-single-icon a,
    .navbar-toggle { color: #111 !important; }
    .icon-bar { background: #111; }

    &.navbar-solid {
      .nav > li {
        &.open > a { color: #bbb !important; }
        > a,
        > a:hover,
        > a:focus {
          color: #bbb !important;
        }
      }
      .logo-wrapper,
      .navbar-header-buttons > li > a,
      .navbar-header-buttons .navbar-single-icon a,
      .navbar-toggle { color: #bbb !important; }
      .icon-bar { background: #bbb; }

      // used for demo purposes
      &.solid-color.navbar {
        nav ul.nav > li {
          &.open > a { color: #bbb !important; }
          > a,
          > a:hover,
          > a:focus {
            color: #bbb !important;
          }
        }
        div.navbar-header {
          .logo-wrapper,
          .navbar-header-buttons > li > a,
          .navbar-header-buttons .navbar-single-icon a,
          .navbar-toggle { color: #bbb !important; }
          .icon-bar { background: #bbb; }
        }
      }
      // ---
    }
    &.navbar-border {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .nav > li > a:hover,
  .nav > li > a:focus,
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    background-color: rgba(255,255,255,.1);
  }

  .nav > li.disabled > a:hover,
  .nav > li.disabled > a:focus {
    background: transparent;
  }


  .map-modal .modal-title {
    color: $dark-theme-color;
  }

  .email-modal .mail-content {
    border-color: #444;
  }

  .dropzone {
    background: #222;
  }

  .navbar-solid {
    background: #222222 !important;
  }

  legend {
    color: $dark-theme-color;
  }
  .modal-content {
    background-color: #222;
  }

  &.fancy-modal {
    button.close {
      background: rgba(255, 255, 255, 0.125);
      &:hover { opacity: 1; background: rgba(255,255,255,.25); }
    }
    .flickr-button-prev,
    .flickr-button-next {
      &:hover { opacity: 1; background: rgba(255,255,255,.25) !important; }
    }
  }

  .dropdown-submenu:after {
    color: $dark-theme-color;
  }

  .fw-button-theme a {
    color: #eee;
    &:hover, &:focus {
      color: #fff !important;
    }
  }

  .fw-button-theme-alt a {
    &:hover,
    &:focus {
      color: #000 !important;
      i { color: #000; }
      span { border-bottom: 1px solid rgba(0,0,0,.3); color: #000 !important; }
    }
  }

  .fw-button-theme-inverse a {
    color: #222;
    &:hover,
    &:focus {
      color: #000 !important;
      i { color: #000; }
      span { border-bottom: 1px solid rgba(0,0,0,.3); color: #000 !important; }
    }
  }

  .fw-button-theme-inverse-alt a {
    color: #222;
    &:hover,
    &:focus {
      color: #fff !important;
      i { color: #fff; }
      span { border-bottom: 1px solid rgba(255,255,255,.5); color: #fff !important; }
    }
  }

  .fw-button-text:hover {
    span { border-bottom: 1px solid rgba(255,255,255, 0.3); }
    span, i { color: white; }
  }

  .fw-grayscale { background: #333; color: #bbb; }

  .fw-heading-default { color: #bbb; }
  .heading-zig-zag .heading-main,
  .heading-line .heading-main {
    background: #222;
  }
  .heading-line:before {
    border-top: 1px solid #333;
  }
  .heading-border .heading-main {
    border-bottom: 1px solid #333;
  }
  .heading-sub .heading-main {
    border-bottom: 1px solid #333;
  }
  .heading-zig-zag {
    background: url("/img/app/zig-zag-w.png") 0 45% repeat-x;
  }

  .flickr-gallery .btn { color: black; }

  .overlay ul li a {
    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.6);
    }
  }

  .icon-box-filled .icon-box-single {
    color: #444;
  }

  .icon-box-colored,
  .icon-box-circle,
  .icon-box-filled {
    .icon {
      color: #444;
    }
  }

  .icon-box .icon-wrapper {
    color: #888;
  }

  .icon-box-wrapper-border {
    border: 1px solid #333;
  }

  .icon-box-wrapper-bg {
    background: #252525;
  }

  button.close {
    text-shadow: none;
    color: #fff;
  }

  .btn {
    color: #ddd;
    &.btn-default {
      color: #777;
      background: #222;
      border-color: #444;
      &:hover { background: #333; }
    }
  }

  .btn-default:active:hover,
  .btn-default:active:focus,
  .btn-default:active.focus,
  .btn-default.active:hover,
  .btn-default.active:focus,
  .btn-default.active.focus,
  .open > .btn-default.dropdown-toggle:hover,
  .open > .btn-default.dropdown-toggle:focus,
  .open > .btn-default.dropdown-toggle.focus {
    background: #444;
  }

  .dropdown-menu {
    background: #222;
    border: 1px solid rgba(255,255,255, 0.15);
  }

  .dropdown-menu > li > a {
    color: #777;
    &:hover, &:focus {
      background-color: #353535;
      color: #eee;
    }
  }



  .portfolio-filter-wrapper {
    > .btn-group .btn,
    > .btn {
      &:hover, &:focus {
        border-color: #444;
        background: #333;
      }
      &.active, &:active {
        background: #333;
        color: #bbb !important;
        border-color: #444;
      }
      &:last-child {
        border-right: 1px solid #444;
      }
    }
  }

  .button-shuffle {
    background: #222;
    border-color: #444;
    color: #777;
    &:hover {
      background: #333;
    }
  }


  .jumbotron {
    background-color: #333;
  }

  .attention-box,
  .jumbotron-border {
    border: 1px solid #444;
  }

  .attention-box-bg-black,
  .jumbotron-bg-black {
    background: #111;
  }

  .attention-box-button-transparent a.button {
    span { color: #bbb; }
    &:hover span { color: #eee; }
  }

  .attention-box-bg-parallax {
    color: #eee;
  }

  .attention-box-bg-gray,
  .attention-box-bg-dark {
    color: #222;
  }

  .attention-box-button-big {
    a.button:hover {
      background: transparent;
      span { color: #eee; }
    }
  }

  .navbar-default .form-group input[type="text"] {
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.5);
    color: rgba(0,0,0,.7);
  }


  .pricing-table-title {
    color: $dark-theme-color;
  }
  .pricing-table-background .pricing-table-title {
    color: #111;
  }

  .pricing-table-colorful .pricing-table-accent .pt-features {
    background: #333 !important;
    > li {
      color: white;
    }
  }
  .pricing-table-accent { background: #222; }

  .pt-footer {
    a {
      color: #eee;
      &:hover { color: #fff !important; }
    }
    &:after { border-top: 0; }
  }

  .pt-table-hor,
  .pt-hor-title,
  .pt-hor-price-wrapper {
    border-color: #444;
  }

  .page-header {
    border-bottom: 1px solid #333;
  }

  .footer-archive .archive-border li { border-bottom: 1px solid #333; }
  .widget-archive .archive-border li { border-bottom: 1px solid rgba(255,255,255,.1); }
  .widget-block-news li { border-bottom: 1px solid rgba(255,255,255,.1); }
  .widget-popular:after { background: #444; }


  .widget-block-news .date { color: #555; }

  .widget-tags ul a {
    border: 1px solid #444;
    &:hover {
      color: #eee;
      border-color: #aaa;
    }
  }

  textarea,
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    background-color: rgba(0, 0, 0, 0.25);
    color: $dark-theme-color;
    @include placeholder {
      color: rgba(255,255,255,.1);
    }
  }

  select {
    background-color: rgba(0,0,0,.25);
    border-color: #555;
  }
  .select2-selection {
    border: 1px solid #444;
    background: rgba(0, 0, 0, 0.25);
  }
  .select2-selection__rendered {
    color: #bbb !important;
  }
  .select2-results,
  .select2-selection__choice {
    color: #444;
  }
  .select2-search__field {
    background: transparent !important;
  }
  hr {
    color: #555;
    border-top: 1px solid #333;
  }
  hr.fa-divider:before {
    background: #222;
    border: 1px solid rgba(255, 255, 255,.15);
  }

  .text-divider > span,
  .text-divider.text-center > span > span {
    background: #222;
  }

  .bootstrap-select {
    > button.dropdown-toggle {
      background: rgba(0,0,0,.25);
      color: $dark-theme-color;
      &.btn-default {
        border: 1px solid #444;
      }
    }
    > div.dropdown-menu {
      > ul.dropdown-menu {
        a {
          color: #bbb;
          &:hover, &:focus {
            color: #fff;
          }
        }
        li.no-results { background: rgba(0,0,0,.35); }
      }
    }
    &.open {
      > button.dropdown-toggle {
        border-color: dimgray;

        &.btn-default {
          background-color: rgba(0,0,0,.5);
        }
      }
    }
  }

  .open > .dropdown-toggle.btn-default:hover,
  .open > .dropdown-toggle.btn-default:focus {
    color: white;
  }


  .mail-subject {
    border-bottom: 0;
    &:focus {
      color: $dark-theme-color;
    }
  }

  .edge-links .edge-left-block a,
  .edge-links .edge-right-block a,
  .edge .social-icons a:last-child {
    border-right: 1px solid #555;
  }

  .full-screen-title-wrapper {
    color: #111;
    &.inverse {
      color: #eee;
    }
  }

  .page-title {
    border-bottom: 1px dashed #333;
    h1 { color: #eee; }
    span { color: #bbb; }
    &.pt-gradient > .container {
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 25%, #222 100%);
    }
  }

  .bottom-gradient {
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 25%, #222 100%);
  }

  .sweet-alert {
    background-color: $dark-theme-bg;
    .sa-icon:not(.sa-info):before {
      background-color: $dark-theme-bg !important;
    }
    .sa-icon:not(.sa-info):after {
      background-color: $dark-theme-bg !important;
    }
    .sa-fix {
      background-color: $dark-theme-bg !important;
      width: 7px !important;
      height: 92px !important;
    }
    h2, p { color: $dark-theme-color; }
    .sa-error-container p { color: #797979; }
  }

  .countdown-block-img .countdown {
    background-color: rgba(0,0,0,.5);
    color: #ddd;
  }

  .countdown-inline {
    color: #aaa;
    &[class*="bg-"] {
      color: #222;
    }
  }

  .coming-soon-inner {
    background-color: rgba(35,35,35,.75);
    color: #ccc;
    .countdown {
      background-color: rgba(0,0,0,0);
    }
  }

  .auth-block {
    background-color: rgba(0,0,0,.7);
    color: #ddd;
  }

  ._404-color-bars-inner {
    background-color: rgba(0,0,0,.9);
  }

  ._404-fuzz-inner {
    ._404-link-wrapper span,
    ._404-title {
      color: #ccc;
    }
  }

  .scroll-up-tab {
    background: $dark-theme-bg none;
    text-shadow: none;
  }

  .breadcrumb {
    a {
      color: #aaa;
      &:focus,
      &:hover {
        color: #fff;
      }
    }
    > .active { color: #fff; }
  }

  .breadcrumb-arrow,
  .breadcrumb-arrow-solid,
  .breadcrumb-pill {
    background-color: transparent !important;
  }

  .breadcrumb-arrow,
  .breadcrumb-arrow-solid {
    > li {
      background: #151515;
      > a { background: #151515;
        &:before { border-color: #151515 #151515 #151515 transparent; }
        &:after { border-left: 16px solid #151515; }
      }
      &:first-child > a:before { border-color: #151515; }
      &:last-child {
        &:hover, &:focus { background: #151515; }
        &:before { border-color: #151515 #151515 #151515 transparent; }
        &:after  { border-left: 16px solid #151515;  }
      }
      &:hover, &:focus {
        background: #111;
        > a {
          background: #111;
          color: white;
          &:before { border-color: #111 #111 #111 transparent; }
          &:after { border-left: 16px solid #111; }
        }
      }
      &.active {
        background: #111;
        &:before { border-color: #111 #111 #111 transparent; }
        &:after { border-left: 16px solid #111; }
        &:hover, &:focus { background: #111; }
      }
    }
  }

  .breadcrumb-arrow-small > li > a:after {
    border-left: 10px solid #151515 !important;
  }

  .breadcrumb-arrow-small > li:hover > a:after {
    border-left: 10px solid #111 !important;
  }




  .breadcrumb-pill > li {
    > a { background: #151515; }
    &:hover a, &:focus a { background: #111; }
    &.active { background: #151515; }
  }

  .fp-show-active span { background: white !important; }

  .thumbnail {
    border: 1px solid #333;
    background-color: transparent !important;
    .caption { color: $dark-theme-color; }
  }

  a {
    color: #ccc;
    &:hover { color: white; }
    &.btn-social-icon {
      color: #fff;
    }
  }
  .button { color: #fff; }
  a.link {
    border-bottom: 1px solid #555;
    &:hover { border-color: #aaa; }
  }

  .slick-arrow:before { color: white !important; }
  .copyright { background: #222; }
  .candy-item { border-color: #222; }

  .tabs-filled.nav-tabs > li > a,
  .tabs-border ~ .tab-content {
    border-color: #444 !important;
  }

  .tabs-filled.nav-tabs > li > a { background: rgba(0,0,0,.1); }

  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    color: white;
    background-color: #222;
    border-color: #555;
    border-bottom-color: transparent;
  }

  .nav-tabs {
    border-bottom-color: #555;
    > li > a:hover { border-color: #555; }
    &.nav-border-bottom li {
      a, a:hover, a:focus {
        border-color: transparent;
      }
    }
    &.tabs-right li.active {
      a, a:hover, a:focus {
        border-color: #555 #555 #555 #222;
        background: transparent;
      }
    }
    &.tabs-left li.active {
      a, a:hover, a:focus {
        border-color: #555 #222 #555 #555;
        background: transparent;
      }
    }
  }
  .tabs-right { border-left: 1px solid #555; }
  .tabs-left { border-right: 1px solid #555; }
  .nav-tabs.nav-justified > li > a { border-bottom-color: #555; }

  .list-group-item {
    background-color: #222;
    border: 1px solid #444;
  }

  a.list-group-item:hover,
  button.list-group-item:hover,
  a.list-group-item:focus,
  button.list-group-item:focus {
    background-color: #2a2a2a;
    color: #ccc;
  }

  a.list-group-item .list-group-item-heading,
  button.list-group-item .list-group-item-heading {
    color: #ccc !important;
  }

  .list-group-item.disabled,
  .list-group-item.disabled:hover,
  .list-group-item.disabled:focus {
    background-color: #333;
    color: #777;
  }

  .panel { background-color: #222; }

  .accordion-vertical { box-shadow: none !important; }
  .panel-default {
    border-color: #333;
    .panel-heading {
      color: #bbb;
      background-color: #333;
      border-color: #333;
    }
  }
  .panel-body { background: #222; }
  .well { background: #222; border: 1px solid #444; }
  .accordion-no-border .panel { border-color: #222 !important; }
  .panel-footer { background-color: #333; border-top: 1px solid #444; }
  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    border-top: 1px solid #444;
  }

  .table > thead > tr > th {border-bottom: 2px solid #444; }

  .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #2f2f2f;
  }


  .table > tbody + tbody {
    border-top: 2px solid #555;
  }

  .table-bordered,
  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > tbody > tr > th,
  .table-bordered > tbody > tr > td,
  .table-bordered > tfoot > tr > th,
  .table-bordered > tfoot > tr > td {
    border: 1px solid #444;
  }

  .table-reflow tr th,
  .table-reflow tr td {
    border: 1px solid #444 !important;
  }

  .thead-default th {
    color: #bbb;
    background-color: #111;
  }

  .table > {
    thead > tr,
    tbody > tr,
    tfoot > tr {
      &.active  > { td, th                 { background-color: #111; } }
                > { td.active, th.active   { background-color: #111; } }

      &.success > { td, th                 { background-color: #081702; } }
                > { td.success, th.success { background-color: #081702; } }

      &.info    > { td, th                 { background-color: #0A222E; } }
                > { td.success, th.success { background-color: #0A222E; } }

      &.warning > { td, th                 { background-color: #413707; } }
                > { td.success, th.success { background-color: #413707; } }

      &.danger  > { td, th                 { background-color: #281010; } }
                > { td.success, th.success { background-color: #281010; } }
    }
  }

  .pagination > li > a,
  .pagination > li > span,
  .pager li > a {
    background-color: #222;
    border: 1px solid #444;
    color: #999;
  }

  .pagination > li > a:hover,
  .pagination > li > a:focus,
  .pagination > li > span:hover,
  .pagination > li > span:focus,
  .pager li > a:hover,
  .pager li > a:focus {
    color: #eee;
    background-color: #333;
  }

  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus,
  .pager .disabled > a,
  .pager .disabled > a:hover,
  .pager .disabled > a:focus,
  .pager .disabled > span {
    color: #3a3a3a;
    background-color: #282828;
  }

  .pagination > .active > a,
  .pagination > .active > a:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span,
  .pagination > .active > span:hover,
  .pagination > .active > span:focus {
    color: #eee;
  }

  .panel > .panel-body + .table,
  .panel > .panel-body + .table-responsive,
  .panel > .table + .panel-body,
  .panel > .table-responsive + .panel-body {
    border-top: 1px solid #444;
  }

  .slider-track {
    background-color: #444;
  }

  .slider-selection {
    background-color: #555;
  }

  .slider.slider-disabled {
    .slider-track {
      background-color: #303030;
    }
    .slider-handle {
      background-color: #444;
    }
  }

  .irs-line-left,
  .irs-line-mid,
  .irs-line-right {
    background: #444;
  }

  .bootstrap-switch .bootstrap-switch-label {
    background: #222;
    box-shadow: inset 0 0 1px #777;
  }

  .liteAccordion.light .av-inner {
    color: #444;
    a {
      color: #333;
      &:hover { color: black; }
      &:focus { color: black; }
    }
  }

  .liteAccordion.dark .av-inner {
    color: #bbb;
    a { color: #fff; }
  }

  .hvr-curl-top-right:before    { background: linear-gradient(225deg, #222 45%, #aaa 50%, #ccc 56%, white 80%); }
  .hvr-curl-top-left:before     { background: linear-gradient(135deg, #222 45%, #aaa 50%, #ccc 56%, white 80%); }
  .hvr-curl-bottom-right:before { background: linear-gradient(315deg, #222 45%, #aaa 50%, #ccc 56%, white 80%); }
  .hvr-curl-bottom-left:before  { background: linear-gradient(45deg,  #222 45%, #aaa 50%, #ccc 56%, white 80%); }

  .rootwizard > ul > li > a {
    background: #222;
    color: #bbb;
    &:hover {
      background: #333;
    }
  }

  .rootwizard > ul > li.active ~ li,
  .rootwizard > ul > li.active ~ li a {
    color: #555;
    border-color: #555;
  }

  .rootwizard > ul > li.active a {
    color: #fff;
  }
  .rootwizard > ul:before { border-bottom: 1px dashed #444; }

  .rootwizard-no-fade > ul > li.active ~ li {
    color: #bbb;
    a {
      color: #bbb;
      border-color: #777;
    }
  }

  &.swag-input {
    input[type="text"],
    input[type="email"],
    input[type="search"],
    input[type="password"] {
      color: $dark-theme-color;
      box-shadow: inset 0 -1px #444;
    }




    .bootstrap-select {
      > button.dropdown-toggle {
        color: $dark-theme-color;
        &.btn-default { box-shadow: inset 0 -1px #444; }
      }
      &.open button.dropdown-toggle { color: $dark-theme-color; }
    }
    .input-group-addon:first-child {
      border-color: #444;
    }
    .input-group-addon:last-child {
      border-left: 1px solid #444;
    }
    .input-group-addon.btn-file {
      border-left: 0;
    }
    .input-daterange .input-group-addon {
      border-color: #444;
    }
    .footer {
      textarea {
        background: #111;
        border-color: #444;
      }
      form .input-group .input-group-addon {
        background: #111;
        border-color: #444;
        color: #999;
      }
    }
    .select2-selection {
      box-shadow: inset 0 -1px rgba(255,255,255, 0.25);
    }
    .form-control.typeahead {
      background: #222 !important;
    }
  }

  .icon-styled {
    &:hover { color: white; }
  }

  .icon-gray { color: #444; }

  .side-icons .side-tab li a {
    color: #ccc;
    &:hover { color: #ccc; }
  }

  .bg-navbar-search {
    background-color: #222222 !important;
    .nav .navbar-search-button a#search-button-link {
      color: #bbb !important;
      &:hover {
        color: #eee !important;
      }
    }
  }
  #navbar-search-input input { background-color: transparent; }

  .footer {
    color: $dark-theme-color;
    background-color: #151515;
    form .input-group .input-group-addon {
      background: #111;
      border-color: #444;
      color: #999;
    }
  }

  .footer-block-news li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }
  .form-control {
    border: 1px solid #444;
    &[data-trigger="fileinput"] {
      background: #222;
    }
    &.typeahead {
      background: rgba(0, 0, 0, 0.25) !important;
    }
  }

  .tt-dataset,
  .datepicker-days {
    color: #444;
  }

  .input-daterange .input-group-addon {
    text-shadow: none;
  }

  .input-group-addon {
    background: rgba(0,0,0,.2);
    border-color: #444;
  }

  .has-error {
    .input-group-addon {
      border-right: 0 !important;
    }
  }
  .has-success {
    .input-group-addon {
      border-right: 0 !important;
    }
  }
  .has-warning {
    .input-group-addon {
      border-right: 0 !important;
    }
  }

  // calendar:

  .fc-head,
  .fc-body {
    background-color: rgba(0,0,0,.5);
  }

  .fc-event {
    background-color: rgba(0, 0, 0, 0.5) !important;
    .fc-bg {
      background: white;
    }
    .fc-content {
      color: #ddd;
    }
  }

  .fc-unthemed {
    td, thead {
      border-color: #333;
      color: #ddd;
    }
  }

  .fc-bg .fc-today {
    background: rgba(0, 0, 0, 0.75);
  }

  .fc-unthemed .fc-divider {
    border-color: #555;
  }

  .fc-ltr .fc-popover .fc-header .fc-title {
    color: black;
  }

  .calendar-no-bg {
    background: transparent;
    .fc-head, .fc-body {
      background-color: #181818;
    }
    .calendar-title,
    .fc-center {
      color: $dark-theme-color;
    }
    .fc-event {
      background-color: #252525 !important;
    }
  }

  // portfolio:

  .portfolio-grid .gallery-item-title {
    color: #ccc;
  }

  .portfolio-title-overlay .gallery-item-title {
    color: #fff;
  }

  .related-projects-title {
    &:before, &:after {
      border-color: #444;
    }
  }

  // blog:

  .blog-single-post-comments .media-body {
    border: 1px solid #444;
  }

  .blog-single-post-comments .avatar img {
    box-shadow: 0 0 0 4px #222, 0 0 0 5px #444;
  }

  .timeline-date-wrapper {
    background: #222;
    box-shadow: 0 0 0 5px #222;
    border: 2px solid #444;
  }

  .blog-timeline:before {
    background: #444;
  }

  .timeline-content {
    border: 2px solid #444;
    &:before { border-right: 11px solid #444;}
    &:after  { border-right-color: #444; }
  }

  .sidebar-gray-left:before,
  .sidebar-gray-right:before {
    background-color: #282828;
    border-color: #303030;
  }

  // shop:

  .shop-item-colors > a:hover,
  .shop-item-colors > a:focus,
  .shop-item-colors > a.active,
  .shop-item-size > a:hover,
  .shop-item-size > a:focus,
  .shop-item-size > a.active {
    border: 2px solid #000;
    color: #fff !important;
  }

  .shop-item-info .add-to-cart-button {
    .button-less, .button-more {
      color: rgba(255, 255, 255, .7);
      &:hover {
        color: rgba(255, 255, 255, 0.9);
      }
    }
    a i {
      color: rgba(255, 255, 255, .7);
    }
    a:hover i {
      color: rgba(255, 255, 255, 0.9);
    }
    input {
      color: rgba(255, 255, 255, .7) !important;
    }
  }

  .shop-single-product-info {
    .product-price,
    .product-additional > div,
    .add-to-cart-block > span,
    .product-quantity-wrapper i:first-child,
    .product-quantity-wrapper i:nth-child(3)  {
      border-color: #444;
    }
  }

  .related-products-title span,
  .shop-single-product .review-body {
    border-color: #444;
  }

  .shop-single-product-info .product-quantity-wrapper input.product-quantity {
    border-color: #444 !important;
  }

  .ss-sidebar-item {
    border-color: #333;
  }

  .shop-single-sidebar-iconboxes .iconbox-wrapper:after {
    background: #444;
  }


  .shop-item-info {
    .price { color: #ddd; }
    .title {
      color: #bbb;
      &:hover {
        color: #fff;
      }
    }
  }

  .shop-item-description-button a {
    color: rgba(255, 255, 255, 0.7);
    &:hover {
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .shop-item-description {
    background: rgba(0,0,0,.8);
    color: #ddd;
  }

  .table-hover > tbody > tr:hover {
    background: rgba(255,255,255,.05);
  }

  .shop-footer {
    background: rgba(255, 255, 255, 0.05);
  }

  .add-to-cart-button input[type="text"] {
    box-shadow: none !important;
  }

  .final-total {
    border-bottom: 1px solid #444;
  }

  .PPModalCart ul,
  .tab-pane-cart ul,
  .shopping-cart ul,
  .wishlist ul,
  .checkout-cart ul {
    border-color: #444;
    .minicart-quantity {
      background-color: transparent;
      border-color: #333 !important;
      box-shadow: none;
    }
  }

  .PPModalCart .minicart-item + .minicart-item,
  .tab-pane-cart .minicart-item + .minicart-item,
  .shopping-cart .minicart-item + .minicart-item,
  .wishlist .minicart-item + .minicart-item,
  .checkout-cart .minicart-item + .minicart-item {
    border-top: 1px solid #333;
  }

  .PPModalCart .minicart-details-remove button,
  .tab-pane-cart .minicart-details-remove button,
  .shopping-cart .minicart-details-remove button,
  .wishlist .minicart-details-remove button,
  .checkout-cart .minicart-details-remove button {
    color: #bbb;
  }

  .PPModalCart .minicart-details-quantity i,
  .tab-pane-cart .minicart-details-quantity i,
  .shopping-cart .minicart-details-quantity i,
  .wishlist .minicart-details-quantity i,
  .checkout-cart .minicart-details-quantity i {
    border-color: #333;
  }

  .PPMiniCart {
    form {
      background: #222;
      box-shadow: 1px 0 0 #333, -1px 0 0 #333, 0 -1px 0 #333, 0 15px 40px 10px rgba(0,0,0,.3);
    }
    ul {
      border-top: 1px solid #303030;
      border-bottom: 1px solid #303030;
    }
    .minicart-item + .minicart-item {
      border-top: 1px solid #303030;
    }
    .minicart-quantity {
      border: 1px solid #303030;
    }
    .minicart-details-img {
      background: #1d1d1d;
      border: 2px solid #2b2b2b;
    }
    .minicart-remove {
      color: #666 !important;
      &:hover, &:focus {
        color: $red !important;
      }
    }
  }

  .progress {
    background-color: #333;
  }

  .faq-border-heading h2 {
    border-color: #444;
  }

  .faq-border-heading-alt h2 {
    &:before, &:after {
      border-color: #555;
    }
  }

  .contact-company-name {
    border-color: #444;
  }

  .contact-page {
    .map-background .container > .row > div {
      background: #333;
    }
  }

  .bg-solid-gray {
    background-color: #1b1b1b !important;
  }

  .events-title span {
    border-color: #444
  }

  .team-member-post {
    color: #bbb;
  }

  i.fa-divider-icon {
    background: #222;
    border-color: #333;
  }

  .default-overlay {
    background: rgba(0,0,0,.35);
  }

  .gsc-search-box {
    background: #222 !important;
  }

}

@media only screen and (min-width: $screen-lg) {
  .dark-theme {
    .blog-timeline:not(.blog-timeline-simple) {
      .timeline-content {
        &:after  { border-left-color: #444; }
        &:before { border-left-color: #444; }
      }
      .blog-timeline-block:nth-child(even) .timeline-content {
        &:after  { border-right-color: #444; }
        &:before { border-right-color: #444; }
      }
    }
  }
}



@media screen and (max-width: $screen-sm-max) {
  .dark-theme {
    .menu {
      .navbar {
        .nav > li > a {
          border-bottom: 1px solid #333;
        }
        .dropdown-menu li > a {
          border-bottom: 1px solid #333;
        }
      }
      .dropdown-menu {
        box-shadow: none;
      }
    }
    .edge-links .edge-right-block a:first-child { border-right: 1px solid #555 !important; }
  }
}

@import "swag-input";