//-- slider --//
.slider {
  height: 100vh !important;
  .slider-nav {
    position: absolute;
    top: 50%;
    z-index: 15;
    font-size: 2rem;
    color: #fff !important;
    opacity: .75;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.3);
    width: 35px;
    height: 50px;
    text-align: center;
    transition: opacity .25s, background .25s;
    &.previous-slide {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &.next-slide {
      right: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    i {
      line-height: 50px;
    }
    &:hover {
      opacity: 1;
      background: rgba(0, 0, 0, 0.6);
    }
  }
  
  .row-el a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.slick-dots { bottom: initial; }

.slider-fullwidth,
.slider-container {
  height: 450px !important;
  margin-top: 100px;
}

.slick-track, .slick-list { height: inherit; }

.row-el {
  position: relative;
  width: 100%;
  background-size: cover !important;
}

.slider-grid-one-row .row-el { height: 100%; }

.slider-grid-two-rows {
  .row-el          { height: 50%; }
  .row-el-offset-1 { top: 50%; }
  .row-el-height-2 { height: 100%; }
}

.slider-grid-three-rows {
  .row-el          { height: 33.3%; }
  .row-el-offset-1 { top: 33.3%; }
  .row-el-offset-2 { top: 66.6%; }
  .row-el-height-2 { height: 66.6%; }
  .row-el-height-3 { height: 100%; }
}

.slider-grid-four-rows {
  .row-el          { height: 25%; }
  .row-el-offset-1 { top: 25%; }
  .row-el-offset-2 { top: 50%; }
  .row-el-offset-3 { top: 75%; }
  .row-el-height-2 { height: 50%; }
  .row-el-height-3 { height: 75%; }
  .row-el-height-4 { height: 100%; }
}

.row-el-width-2 { width: 200%; }
.row-el-width-3 { width: 300%; }
.row-el-width-4 { width: 400%; }
.row-el-width-5 { width: 500%; }


.slider-grid-dots-overflow {
  margin-bottom: 0 !important;
  .slick-dots { bottom: 0 !important; }
}

.slider-grid {
  .lightbox-bg-img .gallery-cell {
    height: 100%;
    padding: 0;
    .gallery-item {
      height: 100%;
      transition: background .25s;
      img {
        visibility: hidden;
      }
      &:hover {
        background: rgba(0,0,0,.4);
      }
    }
  }
}