@import "variables";

/* menu overlay styles */

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 10000;
  /* close button styles */
  .overlay-close {
    width: 80px;
    height: 80px;
    position: absolute;
    right: 20px;
    top: 20px;
    background: url("/img/app/cross.png") no-repeat center center;
    opacity: 0.5;
    z-index: 100;
    border: 2px solid transparent;
    transition: all 0.25s;
    background-size: 60%;
    &:hover {
      border: 2px solid white;
      opacity: 1;
    }
    &:focus {
      outline: none !important;
    }
  }
  nav {
    position: relative;
    top: 50%;
    font-size: 25px;
    display: table;
    width: 100%;
    transform: translateY(-50%);
    text-transform: uppercase;
    font-family: montserrat;
    letter-spacing: 1px;
  }
  ul {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    li {
      overflow-y: hidden;
      width: 100%;
      a {
        color: #fff;
        padding: 0;
        &:hover,
        &:focus {
          background: rgba(255, 255, 255, 0.65) !important;
          color: #000;
        }
        > i {
          margin-right: 5px;
        }
      }
    }
  }
}

/* menu overlay grid 2 / 3 columns: */

@media screen and (max-width: $screen-xs-max) {
  .menu-overlay-grid-2-col nav li,
  .menu-overlay-grid-3-col nav li {
    width: 100% !important;
    min-height: 0 !important;
    height: 100px;
    a {
      line-height: 100px !important;
    }
  }
}


@media screen and (min-width: $screen-sm) and (max-height: 500px) {
  .menu-overlay-grid-2-col nav li,
  .menu-overlay-grid-3-col nav li {
    min-height: 0 !important;
    line-height: 0 !important;
    a {
      line-height: 0 !important;
    }
  }
}

%menu-overlay-grid {
  nav {
    width: 100%;
    height: 100%;
  }
}

.menu-overlay-grid-2-col {
  @extend %menu-overlay-grid;
  nav li {
    float: left;
    width: 50%;
    min-height: 150px;
    line-height: 150px;
    a {
      font-size: 3rem;
      padding: 0;
      height: 100%;
      line-height: 150px;
    }
  }
}

.menu-overlay-grid-3-col {
  @extend %menu-overlay-grid;
  nav li {
    float: left;
    width: 33.3%;
    min-height: 185px;
    line-height: 185px;
    a {
      font-size: 2rem;
      padding: 0;
      height: 100%;
      line-height: 185px;
    }
  }
}

/* menu overlay animation styles: */

%menu-overlay-style-1 {
  .overlay {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0s 0.5s;
    nav {
      perspective: 1200px;
      ul {
        transition: transform 0.5s, opacity 0.5s;
      }
    }
    &.open {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.5s;
      nav ul {
        opacity: 1;
        transform: rotateX(0deg);
      }
    }
    &.closing &:hover { opacity: 0; }
  }
}

.menu-overlay-style-1a {
  @extend %menu-overlay-style-1;
  .overlay         nav ul { transform: translateY(-25%) rotateX(35deg);  }
  .overlay.closing nav ul { transform: translateY(25%)  rotateX(-35deg); }
}

.menu-overlay-style-1b {
  @extend %menu-overlay-style-1;
  .overlay         nav ul { transform: translateY(-25%) rotateX(35deg); }
  .overlay.closing nav ul { transform: translateY(-25%) rotateX(35deg); }
}

.menu-overlay-style-1c {
  @extend %menu-overlay-style-1;
  .overlay         nav ul { transform: translateY(25%)  rotateX(-35deg); }
  .overlay.closing nav ul { transform: translateY(-25%) rotateX(35deg);  }
}

.menu-overlay-style-1d {
  @extend %menu-overlay-style-1;
  .overlay         nav ul { transform: translateY(25%) rotateX(-35deg); }
  .overlay.closing nav ul { transform: translateY(25%) rotateX(-35deg); }
}

$menu-overlay-style-2-offset: 50px;
%menu-overlay-style-2 {
  .overlay {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, transform 0.5s, visibility 0s 0.5s;
    &.open {
      opacity: 1;
      visibility: visible;
      transform: translateY(0%);
      transition: opacity 0.5s, transform 0.5s;
    }
  }
}

.menu-overlay-style-2a {
  @extend %menu-overlay-style-2;
  .overlay { transform: translateY(-$menu-overlay-style-2-offset); }
}

.menu-overlay-style-2b {
  @extend %menu-overlay-style-2;
  .overlay { transform: translateX($menu-overlay-style-2-offset) translateY(-$menu-overlay-style-2-offset); }
}

.menu-overlay-style-2c {
  @extend %menu-overlay-style-2;
  .overlay { transform: translateX($menu-overlay-style-2-offset); }
}

.menu-overlay-style-2d {
  @extend %menu-overlay-style-2;
  .overlay { transform: translateX($menu-overlay-style-2-offset) translateY($menu-overlay-style-2-offset); }
}

.menu-overlay-style-2e {
  @extend %menu-overlay-style-2;
  .overlay { transform: translateY($menu-overlay-style-2-offset); }
}

.menu-overlay-style-2f {
  @extend %menu-overlay-style-2;
  .overlay { transform: translateX(-$menu-overlay-style-2-offset) translateY($menu-overlay-style-2-offset); }
}

.menu-overlay-style-2g {
  @extend %menu-overlay-style-2;
  .overlay { transform: translateX(-$menu-overlay-style-2-offset); }
}

.menu-overlay-style-2h {
  @extend %menu-overlay-style-2;
  .overlay { transform: translateX(-$menu-overlay-style-2-offset) translateY(-$menu-overlay-style-2-offset); }
}

%menu-overlay-style-3 {
  .overlay {
    visibility: hidden;
    transition: transform 0.4s ease-in-out, visibility 0s 0.4s;
    &.open {
      visibility: visible;
      transform: translateY(0%);
      transition: transform 0.4s ease-in-out;
    }
  }
}

.menu-overlay-style-3a {
  @extend %menu-overlay-style-3;
  .overlay { transform: translateY(-100%); }
}

.menu-overlay-style-3b {
  @extend %menu-overlay-style-3;
  .overlay { transform: translateX(100%); }
}

.menu-overlay-style-3c {
  @extend %menu-overlay-style-3;
  .overlay { transform: translateY(100%); }
}

.menu-overlay-style-3d {
  @extend %menu-overlay-style-3;
  .overlay { transform: translateX(-100%); }
}

%menu-overlay-style-4 {
  .overlay {
    visibility: hidden;
    opacity: 0;
    transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
    &.open {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
      transition: transform 0.4s, opacity 0.4s;
    }
  }
}

.menu-overlay-style-4a {
  @extend %menu-overlay-style-4;
  .overlay { transform: scale(0.9); }
}

.menu-overlay-style-4b {
  @extend %menu-overlay-style-4;
  .overlay { transform: scale(0.8); }
}

.menu-overlay-style-4c {
  @extend %menu-overlay-style-4;
  .overlay { transform: scale(0.7); }
}

.menu-overlay-style-4d {
  @extend %menu-overlay-style-4;
  .overlay { transform: scale(0.6); }
}

%menu-overlay-style-5 {
  .overlay {
    visibility: hidden;
    &.open {
      visibility: visible;
      nav,
      .overlay-close {
        opacity: 1;
        transition-delay: 0.5s;
      }
    }
    &.closing nav,
    .overlay-close {
      transition-delay: 0s;
    }
    nav {
      position: absolute;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
    nav,
    .overlay-close {
      opacity: 0;
      transition: opacity 0.3s 0.5s;
    }
  }
}

.menu-overlay-style-5a {
  @extend %menu-overlay-style-5;
  .overlay {
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    transition: width 0.5s 0.3s, visibility 0s 0.8s;
    &.open {
      width: 100%;
      transition: width 0.5s;
    }
  }
}

.menu-overlay-style-5b {
  @extend %menu-overlay-style-5;
  .overlay {
    top: 50%;
    transform: translateY(-50%);
    height: 0;
    transition: height 0.5s 0.3s, visibility 0s 0.8s;
    &.open {
      height: 100%;
      transition: height 0.5s;
    }
  }
}
/* end of menu overlay animation styles */


.menu-overlay button {
  /* reassign bootstrap styles */
  display: inline-block;
}
