@charset 'UTF-8';
.jsfg-loader {
    position: relative;
    top: 50%;
    text-align: center;
}

    .jsfg-loader .animation-dots {
        color: #797979;
        font-size: 20px;
    }

.jsfg-modal {
    width: auto;
}

    .jsfg-modal .modal-header .modal-image {
        width: 560px
    }

    .jsfg-modal .modal-body {
        max-height: none;
        position: relative;
    }
    
        .jsfg-modal .modal-body .modal-image {
            position: relative;
            margin: 0 auto;
            top: 50%;
            text-align: center;
            cursor: pointer
        }

    .jsfg-modal.fade.in {
        top: 50%;
    }
    
    .jsfg-modal .modal-header:after   {
        display: table;
        line-height: 0;
        content: "";
        clear: both;
    }
    
.hide {
    display: none;
}

    @media (min-width:768px) {
        .jsfg-modal .modal-body .modal-image  {
            min-width: 560px;
        }
    }