// ######################
// ### user menu page ###
// ######################

.user-page-container {
  .user-page-list-menu {
    .list-group-item {
      > i {
        position: absolute;
        line-height: 21px;
        width: 15px;
        text-align: center;
      }
      > span {
        margin-left: 20px;
        display: inline-block;
      }
    }
  }

  .user-heading {
    .media-left img {
        height: 150px;
        border-radius: 4px;
        margin-right: 4px;
      }
    .media-body h2 {
        display: inline-block;
        + a {
          margin-left: 10px;
        }
    }
  }
  .team {
    .team-member-img {
      height: 140px;
      text-align: right;
      img {
        width: initial;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .team-member .social-icons { margin-bottom: 0; }
    .team-member-name { font-size: 18px; }
    .team-member-post { font-size: 12px; margin-bottom: 12px; }
  }
}

// ###########
// ### faq ###
// ###########

.faq-panels {
  .panel {
    margin-bottom: 30px;
  }
  .panel-heading {
    text-transform: uppercase;
    font-family: $montserrat;
    font-weight: 600;
    font-size: 14px;
  }
}

.faq-accordions {
  .accordion {
    margin-bottom: 30px;
  }
  .accordion-title {
    text-transform: uppercase;
    span {
      color: #aaa;
      font-family: $montserrat;
      font-weight: 400;
    }
  }
}

.question-block {
  margin-bottom: 40px;
  span:first-child {
    text-transform: uppercase;
    font-weight: 700;
    font-family: $raleway;
    margin-bottom: 10px;
    margin-top: 20px;
    display: inline-block;
  }
}

.faq {
  .attention-box {
    margin-top: 82px;
  }
}

.faq-border-heading {
  text-align: center;
  h2 {
    display: inline-block;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    padding: 15px 30px;
  }
}

.faq-border-heading-alt {
  text-align: center;
  h2 {
    display: inline-block;
    position: relative;
    &:before {
      content: '';
      border-bottom: 1px solid #eaeaea;
      position: absolute;
      bottom: -10px;
      width: 100%;
    }
    &:after {
      content: '';
      border-bottom: 1px solid #eaeaea;
      position: absolute;
      bottom: -25px;
      width: 100%;
      left: 0;
    }
  }
}

.contact-page {
  .map-default {
    .map-canvas, .map-canvas-wrapper {
      min-height: 425px;
    }
  }
  .map-compact {
    .map-canvas, .map-canvas-wrapper {
      min-height: 260px;
    }
  }
  .map-background {
    position: relative;
    z-index: 5;
    .map {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .map-canvas-wrapper { height: 100%; }
    .container {
      z-index: 10;
      > .row > div {
        background: #fff;
        border-radius: 4px;
        padding: 45px 35px 30px;
      }
    }
    &.map-background-center {
      padding: 600px 0 35px 0;
      .container {
        max-width: 800px;
      }
    }
    &.map-background-side {
      padding: 100px 0;
    }
  }
  .follow-text {
    margin-top: 5px;
  }
  &.contact-compact {
    .button[data-target=".contact-modal"] {
      height: 42px;
    }
  }
  .contact-form-single {
    max-width: 800px;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .contact-page .map-background {
    padding: 0 !important;
    .map { position: relative; }
    > .container { padding-top: 3rem; }
  }
}