.pricing-table-title {
  color: #333;
  text-transform: uppercase;
  margin-bottom: 35px;
  font-weight: 400;
  font-size: 35px;
}

.pricing-table-4-col,
.pricing-table-5-col{
  .title {
    margin-bottom: 0 !important;
  }
}

.pricing-table-4-col:not(.pricing-table-colorful),
.pricing-table-5-col:not(.pricing-table-colorful) {
  .title {
    margin-bottom: 0 !important;
    border-bottom: 3px solid #222;
  }
}

.pricing-table-colorful .pricing-table-accent .pt-features { background: white !important; }

.pricing-table-accent {
  transform: scale(1.025);
  background: white;
  z-index: 5;
  box-shadow: 0 5px 15px black;
  .pt-footer:after { top: -1px; }
}

.pricing-table-background {
  background-size: cover;
  position: relative;
  .pt-features {
    color: #bbb;
  }
  .pt-footer a {
    color: #eee;
  }
}

.pricing-table {
  font-family: $roboto;
  font-size: 14px;
  li { list-style-type: none; }
  .title,
  .pt-footer {
    color: #FEFEFE;
    line-height: 2.5;
    position: relative;
    font-weight: 500;
  }

  .content {
    position: relative;
    color: #FEFEFE;
    padding: 20px 0 10px 0;
  }

  .title {
    background: rgba(0,0,0,.65);
  }
  .content,
  .pt-footer {
    background: rgba(0,0,0,.5);
  }


  .content:after,
  .content:before,
  .pt-footer:before,
  .pt-footer:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .content:after,.pt-footer:after {
    border: 5px rgba(136, 183, 213, 0);
    margin-left: -5px;
  }

  .hint {
    font-style: italic;
    height: 24px;
  }

  .pt-features {
    background: white;
    padding: 30px 50px;
    margin-bottom: 0;
    background: rgba(0,0,0,.05);
    li{
      padding: 10px 0;
      font-family: $lato;
      text-align: left;
      .fa { margin-right: 10px; }
    }
  }

  .price {
    position: relative;
    display: inline-block;
    margin-bottom: 0.625em;
    > span {
      font-size: 6rem;
      letter-spacing: -5px;
    }
    sup {
      font-size: 1rem;
      position: absolute;
      top: 45px;
      left: -10px;
    }
    sub {
      font-size: 1rem;
      position: absolute;
      bottom: 35px;
      right: -30px;
    }
  }
}

.pricing-table-2-col,
.pricing-table-3-col {
  .title {
    margin-bottom: 30px;
  }
}

.pt-footer {
  text-transform: capitalize;
  &:before, &:after { top: 0; }
  &:after { border-top-color: #FFFFFF; }
  a {
    color: #252525;
    height: 100%;
    width: 100%;
    display: inline-block;
    &:hover,
    &:focus {
      color: black !important;
    }
  }
  p {
    margin-bottom: 0;
  }
}

.pricing-table-theme .pt-footer a {
  color: #eee;
  &:hover,
  &:focus {
    color: #fff !important;
  }
}

.pt-table-hor {
  border: 1px solid #ddd;
  border-radius: 2px;
  overflow: hidden;
}

.pt-table-hor-left {
  .pt-hor-title {
    padding-right: 30px;
  }
}

.pt-table-hor-right {
  .pt-hor-title {
    margin-right: -30px;
  }
}

.pt-hor-main > .row {
  padding: 25px 15px;
  position: relative;
}

.pt-hor-sum {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1;
  position: relative;
  left: 4px;
  margin-bottom: 20px;
  span.currency {
    font-size: 19px;
    position: relative;
    top: -34px;
    left: 13px;
    font-family: $raleway;
    font-weight: 500;
  }
  span.mo {
    font-size: 14px;
    position: relative;
    left: -20px;
    font-weight: 600;
    font-family: $raleway;
  }
}

.pt-hor-sum-text {
  text-transform: uppercase;
  font-family: $montserrat;
  margin-bottom: 15px;
  font-weight: 600;
}

.pt-hor-title {
  text-transform: uppercase;
  letter-spacing: .5px;
  border-bottom: 1px solid #ddd;
  padding: 25px 0 25px 25px;
  margin-left: -30px;
  margin-bottom: 0;
  text-align: center;
}

.pt-hor-price-wrapper {
  display: table;
  width: 100%;
  padding: 30px 15px;
}

.pt-hor-price {
  display: table-cell;
  vertical-align: middle;
  .button {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.pt-big-icon {
  font-size: 75px;
  float: left;
  margin-right: 5px;
}

.pt-big-icon-wrapper {
  text-align: center;
  margin-top: 15px;
}

ul.pt-hor-list {
  margin-bottom: 0;
  line-height: 1.9;
  li {

    > i {
      margin-right: 10px;
      width: 20px;
      text-align: center;
      font-size: 16px;
      vertical-align: middle;
    }
  }
}

.pt-big-icon-text-1 {
  font-size: 25px;
  font-family: $oswald;
}

.pt-big-icon-text-2 {
  font-size: 12px;
  line-height: 1.3;
  margin-top: 5px;
}

@media screen and (min-width: $screen-md) {
  .pricing-table-4-col,
  .pricing-table-5-col {
    .pt-features {
      padding: 15px 20px !important;
    }
  }
  .pt-table-hor-left {
    .pt-hor-main > .row {
      left: -30px;
    }
  }

  .pt-table-hor-left {
    .pt-hor-price-wrapper {
      border-right: 1px solid #ddd;
    }
  }

  .pt-table-hor-right {
    .pt-hor-price-wrapper {
      border-left: 1px solid #ddd;
    }
  }
}

@media screen and (max-width: $screen-sm-max) {
  .pricing-table .pt-features {
    padding: 30px;
  }
  .pricing-table .row > div {
    margin-bottom: 30px;
  }
  .pt-table-hor .row > div {
    margin-bottom: 0;
  }
  .pt-big-icon-wrapper {
    padding-left: 55px;
    padding-right: 55px;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 0 !important;
  }
  .pt-hor-price-wrapper {
    text-align: left;
  }
  .pt-hor-price {
    text-align: center;
    display: inline-block;
    padding-left: 40px;
    padding-right: 40px;
  }
  .pt-hor-title {
    margin: 0 !important;
    padding: 25px;
  }
}

@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
  .price-3s-term {
    right: 10px;
    position: relative;
  }
  .pt-hor-price .button {
    padding-left: 30px;
    padding-right: 30px;
  }
  ul.pt-hor-list {
    padding-left: 0;
  }
}
