.filled {
  overflow: hidden;
  color: #252525;
}

.filled-col {
  overflow: hidden;
  min-height: inherit;
  padding: 15px;
}

.filled-text {
  padding-right: 30px;
  padding-left: 30px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  p { margin: 20px 0; }
}

.filled-text-title {
  margin-top: 0.5rem !important;
}

.filled-icon {
  float: left;
  position: relative;
  left: 10px;
  top: 10px;
  i {
    font-size: 250px;
    opacity: 0.15;
    position: absolute;
    left: 0;
    right: 0;
  }
  .img-wrapper {
    opacity: 0.15;
    position: absolute;
    left: 0;
    right: 0;
    img {
      width: 200px;
    }
  }
}

.filled-icon-small   i { font-size: 150px; }
.filled-icon-large   i { font-size: 250px; }
.filled-icon-huge    i { font-size: 300px; }
.filled-icon-clearer i { opacity: 0.1 }
.filled-icon-solider i { opacity: 0.5 }
.filled-icon-solid   i { opacity: 1   }


// filled mono styles
.filled-mono .filled-col {
  display: table;
  .filled-icon {
    left: 0;
    float: none;
  }
  .filled-text {
    text-align: left;
  }
}

.filled-mono-content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

$beige1: #FCD088;
$beige2: #DDAE76;
$beige3: #CD9E6A;
$beige4: #af875b;

.filled-perspective {
  .img-wrapper {
    height: 200px;
    text-align: center;
    position: relative;
    &.no-img .perspective-text {
      position: initial;
    }
  }
  .perspective-text {
    position: relative;
    top: -15px;
    right: 25px;
    line-height: 200px;
    font-family: $oswald;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    font-size: 25px;
  }
  img {
    height: 100px;
  }
  > .row > div {
    &:nth-child(1) {
      background-color: #202529;
    }
    &:nth-child(2) {
      background-color: $beige1;
    }
    &:nth-child(3) {
      background-color: $beige2;
    }
    &:nth-child(4) {
      background-color: $beige3;
    }
  }
}

.filled-massive {
  .row > div {
    padding: 50px;
    &:nth-child(1) {
      background-color: $beige1;
    }
    &:nth-child(2) {
      background-color: $beige2;
    }
    &:nth-child(3) {
      background-color: $beige3;
    }
    &:nth-child(4) {
      background-color: $beige4;
    }
  }
}

@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
  .filled-massive .row > div {
    padding: 40px;
  }
  .filled-col.col-md-3 .filled-text {
    padding-right: 15px;
    padding-left: 15px;
  }
}