// ********
// TIMELINE
// ********

.blog-timeline {
  position: relative;
  padding: 30px 0;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 10px;
    height: 100%;
    width: 2px;
    background: #ddd;
  }
  &.big-circles {
    .timeline-date-wrapper {
      width: 70px !important;
      height: 70px !important;
      margin-left: -25px;
    }
    .timeline-date { margin-top: 13px; }
    .timeline-content { padding: 1.6rem 2rem;}
    .timeline-content:before { top: 22px; }
    .timeline-content:after  { top: 25px; }
  }
}


.blog-timeline-block {
  position: relative;
  margin: 4rem 0;
  &:first-child { margin-top: 0; }
  &:last-child  { margin-bottom: 0; }
}


.timeline-date-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  margin-left: -20px;
  border-radius: 50%;
  border: 2px solid #ddd;
  background: #fff;
  box-shadow: 0 0 0 5px #fff;

  .timeline-date {
    text-align: center;
    line-height: 1;
    width: 100%;
    display: inline-block;
    font-family: $oswald;
    margin-top: 11px;
    font-size: 13px;
    span {
      font-size: 18px;
      display: block;
    }
  }
}

.timeline-content {
  position: relative;
  margin-left: 60px;
  border-radius: 3px;
  padding: 2rem;
  border: 2px solid #ddd;

  &:after {
    content: '';
    position: absolute;
    top: 20px;
    height: 0;
    width: 0;
    left: -16px;
    border: 8px solid transparent;
    border-right-color: #fff;
  }

  &:before {
    content: '';
    position: absolute;
    top: 17px;
    height: 0;
    width: 0;
    right: 100%;
    border: 11px solid transparent;
    border-right: 11px solid #ddd;
  }
}

@media only screen and (min-width: $screen-lg) {
  .blog-timeline:not(.blog-timeline-simple) {
    &:before {
      left: 50%;
      margin-left: -2px;
    }

    .blog-timeline-block {
      margin: 50px 0;
      &:first-child { margin-top: 0; }
      &:last-child  { margin-bottom: 0; }
      &:nth-child(even) .timeline-content {
        float: right;
        &:before {
          top: 17px;
          left: auto;
          right: 100%;
          border-color: transparent;
          border-right-color: #ddd;
        }
        &:after {
          content: '';
          position: absolute;
          top: 20px;
          left: -16px;
          height: 0;
          width: 0;
          border: 8px solid transparent;
          border-right-color: #fff;
        }
      }
    }
    .timeline-date-wrapper {
      width: 60px;
      height: 60px;
      left: 50%;
      margin-left: -30px;
      /* Force Hardware Acceleration in WebKit */
      -webkit-transform: translateZ(0);
      -webkit-backface-visibility: hidden;
    }

    .timeline-content {
      margin-left: 0;
      padding: 25px;
      width: 45%;
      &:before {
        top: 17px;
        left: 100%;
        border-color: transparent;
        border-left-color: #ddd;
      }
      &:after {
        content: '';
        position: absolute;
        top: 20px;
        height: 0;
        width: 0;
        right: -16px;
        left: auto;
        border: 8px solid transparent;
        border-left-color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .blog-timeline.big-circles {
    .blog-timeline-block {
      margin-left: 20px;
    }
    &:before {
      left: 30px;
    }
  }
}

// ****
// BLOG
// ****

.blog-img-wrapper {
  max-height: 350px;
  overflow: hidden;
  margin-bottom: 2rem;
  a {
    img {
      width: 100%;
    }
    &.slick-slider {
      .slick-track,
      .slick-list {
        height: 100%;
        max-height: inherit;
      }
      .slick-slide {
        overflow: hidden;
        max-height: inherit;
      }
    }
  }
  .slick-slider {
    max-height: inherit;
    .slick-arrow {
      height: 50px;
    }
  }

  .gallery-cell {
    width: 100%;
  }
}

.blog-map-wrapper {
  margin-bottom: 2rem;
  height: 350px;
  .map-canvas {
    min-height: initial;
  }
}

.blog-media-wrapper {
  height: 350px;
  margin-bottom: 2rem;
  iframe {
    width: 100%;
    height: inherit;
  }
}

.blog-post {
  margin: 1rem auto;
  &:first-child {
    margin-top: 0;
  }
  &.slick-slide {
    margin-top: 0;
  }
}

.blog-post-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  > a {
    font-family: $raleway;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: .5rem;
    display: block;
    span.title-related {
      font-size: 18px;
      font-weight: 700;
      display: block;
    }
  }
}

.blog-read-more-button {
  display: inline-block;
  margin-top: 1rem;
  transition: color .25s;
  a {
    text-transform: uppercase;
    font-family: $montserrat;
    font-size: 14px;
    font-weight: 600;
  }
}

.blog-post-info {
  font-family: $montserrat;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 5px;
  > span {
    margin-right: 1rem;
    white-space: nowrap;
    display: inline-block;
    &:last-child {
      margin-right: 0;
    }
  }
  + .lightbox {
    margin-bottom: 2rem;
  }
}

.blog-post-info-sidebar {
  margin-bottom: 0;
  margin-top: 20px;
}

.post-content {
  .blog-read-more-button {
    margin-top: 10px;
  }
  .blog-post-title {
    margin-bottom: 7px;
  }
  .blog-img-wrapper,
  .blog-media-wrapper {
    margin-bottom: 25px;
  }
}

.blog-post-single {
  .blog-post-info {
    margin-bottom: 20px;
  }
  p.content {
    margin-bottom: 30px;
  }
  pre[class*="language-"] {
    margin-bottom: 30px !important;
  }
}

.blog-single-post-share > span {
  margin-right: 15px;
  position: relative;
  bottom: -5px;
}

.blog-related-posts {
  .blog-post {
    margin-bottom: 5px;
  }
}

.blog-single-post-comments {
  max-width: 850px;
  .avatar {
    width: 60px;
    margin: 10px 20px 0 0;
    img {
      width: inherit;
      border-radius: 200px;
      box-shadow: 0 0 0 4px #fff, 0 0 0 5px #ddd;
    }
  }
  .media-body {
    border: 1px solid #eee;
    padding: 30px;
    border-radius: 4px;
    position: relative;
  }
  .comment {
    margin: 20px auto;
    &:last-child {
      margin-bottom: 0;
    }
    &.replied-comment {
      padding-left: 80px;
      position: relative;
      > i {
        position: absolute;
        left: 12px;
        top: 12px;
      }
    }
    .comment-time {
      margin-bottom: 20px;
    }
  }
  .comment-reply-button {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    font-family: $montserrat;
    margin-top: 20px;
    color: #555;
  }
}

.blog-leave-comment {
  margin: 50px auto;
}

.blog-masonry {
  margin: auto -10px;
  .blog-post {
    display: inline-block;
    width: 100%;
  }
  .blog-img-wrapper {
    max-height: none;
    a:not(.slick-slider) {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      margin-bottom: -5px;
    }
  }
}

.blog-masonry {
  .blog-post:nth-child(1)  { .blog-img-wrapper a, .blog-media-wrapper { height: $masonry-6;  }}
  .blog-post:nth-child(2)  { .blog-img-wrapper a, .blog-media-wrapper { height: $masonry-2;  }}
  .blog-post:nth-child(3)  { .blog-img-wrapper a, .blog-media-wrapper { height: $masonry-16; }}
  .blog-post:nth-child(4)  { .blog-img-wrapper a, .blog-media-wrapper { height: $masonry-4;  }}
  .blog-post:nth-child(5)  { .blog-img-wrapper a, .blog-media-wrapper { height: $masonry-5;  }}
  .blog-post:nth-child(6)  { .blog-img-wrapper a, .blog-media-wrapper { height: $masonry-1;  }}
  .blog-post:nth-child(7)  { .blog-img-wrapper a, .blog-media-wrapper { height: $masonry-7;  }}
  .blog-post:nth-child(8)  { .blog-img-wrapper a, .blog-media-wrapper { height: $masonry-8;  }}
  .blog-post:nth-child(9)  { .blog-img-wrapper a, .blog-media-wrapper { height: $masonry-9;  }}
  .blog-post:nth-child(10) { .blog-img-wrapper a, .blog-media-wrapper { height: $masonry-10; }}

}

@media screen and (min-width: $screen-md) {
  .blog-tiny-sidebar {
    .post-content {
      width: calc(100% - 75px);
    }
    .blog-post-info-sidebar {
      width: 70px;
      margin-top: 0;
      .fa { font-size: 16px; }
      > span {
        margin-bottom: 10px;
        font-size: 9px;
        font-weight: 700;
      }
    }
    .blog-post-title > a {
      font-size: 17.5px;
      font-weight: 800;
    }
  }
  .blog-tiny-heading .blog-post-title > a {
    font-size: 20px;
  }

  .blog-post-info-sidebar {
    width: 100px;
    float: left;
    > span {
      margin-right: 0;
      display: block;
      margin-bottom: 25px;
    }
    .fa {
      display: block;
      text-align: center;
      font-size: 2rem;
      + span {
        text-align: center;
        display: block;
        white-space: normal;
      }
    }
  }

  .post-content {
    width: calc(100% - 115px);
    float: right;
    .blog-read-more-button {
      margin-top: 10px;
    }
    .blog-post-title {
      margin-top: 5px;
      margin-bottom: 7px;
    }
  }

  .blog-small-thumbs {
    .blog-img-wrapper,
    .blog-media-wrapper {
      width: 300px;
      float: left;
      margin-right: 25px;
      max-height: 200px;
    }
    .blog-media-wrapper iframe {
      max-height: inherit;
    }
    .blog-img-wrapper,
    .blog-media-wrapper,
    .post-content .blog-img-wrapper,
    .post-content .blog-media-wrapper {
      margin-bottom: 20px;
    }
    .blog-post-title,
    .post-content .blog-post-title {
      display: inline;
    }
    .blog-post-title > a {
      margin-bottom: 0;
    }
    .blog-preview-text {
      padding-left: 325px;
    }
    .blog-post-info {
      margin-bottom: 1rem;
    }
    .blog-post-info-sidebar {
      margin-top: 0;
    }
    .blog-post-info-sidebar {
      .fa { font-size: 22px; }
      > span { margin-bottom: 13px; }
    }
  }

  .blog-masonry-2-col {
    .blog-post {
      width: calc(50% - 20px);
      margin: auto 10px;
    }
  }

  .blog-masonry-3-col {
    .blog-post {
      width: calc(33% - 20px);
      margin: auto 10px;
    }
  }

  .blog-masonry-4-col {
    .blog-post {
      width: calc(25% - 20px);
      margin: auto 10px;
    }
  }

  .blog-masonry-5-col {
    .blog-post {
      width: calc(20% - 10px);
      margin: auto 5px;
    }
  }

  .blog-5-col {
    .blog-img-wrapper a:not(.slick-slider) {
      overflow: hidden;
      display: inline-block;
      margin-bottom: -5px;
    }
    .blog-img-wrapper a,
    .blog-media-wrapper {
      height: 100px;
    }
    .slick-list, .slick-track {
      height: 100%;
    }
  }

  .blog-4-col {
    .blog-img-wrapper a:not(.slick-slider) {
      overflow: hidden;
      display: inline-block;
      margin-bottom: -5px;
    }
    .blog-img-wrapper a,
    .blog-media-wrapper {
      height: 150px;
    }
    .slick-list, .slick-track {
      height: 100%;
    }
  }

  .blog-3-col {
    .blog-img-wrapper a:not(.slick-slider) {
      overflow: hidden;
      display: inline-block;
      margin-bottom: -5px;
    }
    .blog-img-wrapper a,
    .blog-media-wrapper {
      height: 200px;
    }
    .slick-list, .slick-track {
      height: 100%;
    }
  }

  .blog-two-col {
    .blog-img-wrapper a:not(.slick-slider) {
      overflow: hidden;
      display: inline-block;
      margin-bottom: -5px;
    }
    .blog-img-wrapper a,
    .blog-media-wrapper {
      height: 300px;
    }
    .slick-list, .slick-track {
      height: 100%;
    }
  }
}


@media screen and (max-width: $screen-xs-max) {
  .blog-related-posts .blog-img-wrapper {
    margin-bottom: 1rem;
  }
}






