@import "variables";

/* menu off canvas styles */

.menu-off-canvas {
  .navmenu {
    position: fixed;
    width: 300px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background: #222;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    /* enables momentum scrolling in iOS overflow elements */
    transition: transform 0.2s cubic-bezier(.16, .68, .43, .99);
    a {
      display: block;
      color: #b3b3b1;
      padding: 7px 45px;
      text-decoration: none;
      text-transform: uppercase;
      font-family: $raleway;
      &:hover,
      &:focus {
        color: #fff !important;
      }
    }
  }
  .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    color: white;
  }
  .navbar { transition: all 0.2s cubic-bezier(.16, .68, .43, .99); }
  button {
    /* reassign bootstrap styles */
    display: inline-block;
  }

  /* subitems */
  .navbar-collapse {
    margin: 0 0 5rem 0 !important;
    padding: 0 !important;
    max-height: none;
    height: initial !important;
  }
  .nav a {
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 13px;
  }
  .navbar-single-icon {
    display: block !important;
    a span {
      display: inline-block !important;
    }
    padding-top: 30px;
    &:before {
      content: '';
      border-top: 1px solid #333;
      display: block;
      width: 150px;
      margin-left: 45px;
      padding-bottom: 30px;
    }
    ~ .navbar-single-icon {
      padding-top: 0;
      &:before { content: ''; display: none; }
    }
  }
  ul {
    padding: 0;
  }
  .nav li {
    float: none;
    overflow: hidden;
  }
  ul.nav > .dropdown > .dropdown-menu {
    margin: 10px 25px 0 25px;
  }
  .dropdown-menu {
    padding: 0;
    li:hover {
      background: transparent;
    }
  }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    margin-left: 20px;
  }
  .dropdown-submenu {
    padding-right: 0;
    &:after { display: none; }
    & > a:after {
      position: absolute;
      content: "\f107";
      font: normal normal normal 14px/1 FontAwesome;
      transition: all 0.25s;
      right: 20px;
      top: 8px;
    }
    & > a:hover,
    & > a:focus {
      &:after { color: #fff; }
    }
  }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1,
  .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2,
  .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3,
  .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4,
  .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5,
  .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6,
  .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7,
  .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8,
  .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9,
  .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10,
  .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11,
  .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    width: 100%;
  }
  .dropdown-submenu:after {
    top: 7.5px;
    color: #b3b3b1;
    right: 20px;
  }
  .navbar-collapse {
    border-top: 0;
    box-shadow: none;
  }
  .multi-column .row > div {
    border-right: 0 !important;
  }
  .navbar .dropdown-menu > li > a:hover,
  .navbar .dropdown-menu > li > a:focus,
  .multi-column-dropdown > li > a:hover,
  .multi-column-dropdown > li > a:focus {
    left: 0;
    background: transparent;
  }
  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus {
    background: transparent;
  }
  .candy-item {
    display: none;
  }
  .dropdown-menu-title {
    color: #ccc !important;
  }
  .navmenu .navbar-collapse .dropdown-menu li a {
    padding: 5px 30px !important;
    margin: 0 !important;
    font-size: 11px;
    line-height: 20px;
  }
  .navbar-nav {
    margin: 0;
    width: 100%;
    float: none;
  }
  .nav .open > a {
    background: transparent;
    &:focus, &:hover {
      background: transparent;
    }
  }
  .navbar-collapse.in {
    overflow-y: visible;
  }
  .collapse {
    display: block;
  }
  .navbar-language-icon {
    height: 20px;
  }
  .row-grid-12 {
    .multi-column-dropdown li > a:hover,
    .multi-column-dropdown li > a:focus,
    .dropdown-menu li > a:hover,
    .dropdown-menu li > a:focus {
      left: 0 !important;
    }
  }
  .rg-12-title span { color: #b3b3b1; }
  hr { border-top: 1px solid #333; }
  .navmenu-brand {
    font-size: 2.5rem;
    cursor: default;
    a {
      padding: 25px 45px 45px 45px;
      span {
        font-size: 1rem;
        display: block;
        line-height: 0;
        margin-left: 2px;
      }
    }
  }
  .dropdown {
    transition: margin-top .25s;
    &.open + .dropdown {
      margin-top: 10px;
    }
  }
  .label { font-size: 7px; display: inline-block; }
  .rg-12-title {
    margin-left: 20px;
    width: initial;
    text-align: left;
    br { display: none; }
  }
  .dropdown-img .row { background: none !important; }
  .col-md-15 { width: 100%; }
  .dropdown-grid li {
    width: 33%;
    float: left;
  }
  .quick-form {
    margin: auto 45px;
    padding-top: 30px;
    textarea {
      resize: vertical;
    }
    &:before {
      content: '';
      border-top: 1px solid #333;
      display: block;
      width: 150px;
      padding-bottom: 30px;
    }
    .quick-form-title {
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 13px;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
  }
  .label-cart { font-size: 12px; }
}

.menu-off-canvas-hover-alt .navmenu a {
  &:hover,
  &:focus {
    background: transparent !important;
  }
}

.menu-off-canvas-light .navmenu {
  background: #eee;
  a { color: #111; }
  .navbar-single-icon:before { border-top: 1px solid #ddd; }
  .quick-form {
    background: #eee;
    color: #111;
    &:before { border-top: 1px solid #ddd; }
    textarea { background: #eee; }
    textarea, input {
      color: #111 !important;
      @include placeholder {
        color: rgba(0,0,0,.2);
      }
    }
    .input-group-addon {
      background: #ddd;
    }
  }
}


/* Menu Movement */

.posDefault   { left:   0 !important; }
.posLeft      { left:  -300px; }
.posRight     { left:   300px; }
.posDefault-R { right:  0 !important; }
.posLeft-R    { right:  300px; }
.posRight-R   { right: -300px; }


.menu-off-canvas-left-push,
.menu-off-canvas-left-slide {
  .navmenu { left: -300px; }
  .navbar button { float: left; }
}

.menu-off-canvas-right-push,
.menu-off-canvas-right-slide {
  .navmenu {
    right: -300px;
    left: auto;
  }
}


.csstransforms3d {
  .posDefault { transform: translate3d(0, 0, 0) !important; }
  .posLeft    { transform: translate3d(-300px, 0, 0) !important; left: 0; }
  .posRight   { transform: translate3d(300px, 0, 0) !important; left: 0; }

  .menu-off-canvas-left-push,
  .menu-off-canvas-left-slide {
    .navmenu {
      transform: translate3d(-300px, 0, 0);
      left: 0;
    }
  }

  .menu-off-canvas-right-push,
  .menu-off-canvas-right-slide {
    .navmenu {
      transform: translate3d(300px, 0, 0);
      right: 0;
      left: auto !important;
    }
  }
}

.body-off-canvas-push-right { left: -300px !important; }
.body-off-canvas-push-left  { left:  300px !important; }

.site-overlay {
  display: none;
  animation: fade 500ms;
}

.pushy-active .site-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fade 500ms;
  cursor: url('/img/app/cross-small.png'), default;
}

@keyframes fade {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fade {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fade {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fade {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}


/* Example Media Query */

@media screen and (max-width: $screen-xs-max) {
  .menu-off-canvas .navmenu {
    font-size: 1.0em;
  }
}

.body-off-canvas-push-alt-anim {
  transition: all 1s cubic-bezier(.16, .68, .43, .99);
  left: 0;
  position: relative;
  .navbar { transition: all 1s cubic-bezier(.16, .68, .43, .99); }
}

.menu-off-canvas-push-alt-anim .navmenu {
  transition: transform 0s !important;
}

.body-off-canvas {
  transition: all 0.2s cubic-bezier(.16, .68, .43, .99);
  left: 0;
  position: relative;
}
