@import "variables";

.swag-input {
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    padding: 3px !important;
    border: 0 !important;
    border-radius: 0;
    background-color: transparent;
    box-shadow: inset 0 -1px #ccc;
    color: #333;
    transition: box-shadow ease-in-out 0.15s;
  }

  .bootstrap-select {
    > button.dropdown-toggle.btn-primary {
      box-shadow: inset 0 -1px #2e6da4;
      + div.dropdown-menu { border-color: #2e6da4; }
    }

    > button.dropdown-toggle.btn-success {
      box-shadow: inset 0 -1px #4cae4c;
      + div.dropdown-menu { border-color: #4cae4c; }
    }

    > button.dropdown-toggle.btn-info {
      box-shadow: inset 0 -1px #46b8da;
      + div.dropdown-menu { border-color: #46b8da; }
    }

    > button.dropdown-toggle.btn-warning {
      box-shadow: inset 0 -1px #eea236;
      + div.dropdown-menu { border-color: #eea236; }
    }

    > button.dropdown-toggle.btn-danger {
      box-shadow: inset 0 -1px #d43f3a;
      + div.dropdown-menu { border-color: #d43f3a; }
    }

    > button.dropdown-toggle.btn-inverse {
      box-shadow: inset 0 -1px #555;
      + div.dropdown-menu { border-color: #555; }
    }



    > button.dropdown-toggle {
      border: 0 !important;
      border-radius: 0 !important;
      background-color: transparent !important;
      box-shadow: inset 0 -1px #ccc;
      padding-left: 3px;
      color: #333;
    }
    > div.dropdown-menu {
      margin-top: -2px;
      .bs-searchbox input {
        color: #333;
      }
    }
    &.open {
      > button.dropdown-toggle {
        color: #333;
      }
    }
  }

  .select2-selection {
    border: 0 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    box-shadow: inset 0 -1px #ccc;
  }
  .select2-selection__rendered {
    padding-left: 3px !important;
  }
  .select2-container--open .select2-dropdown--above {
    border-bottom: 1px solid #aaa;
  }

  .input-daterange .input-group-addon {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }


  .has-error {
    .form-control:not(textarea) {
      &:focus {
        box-shadow: inset 0 -1px lighten($red_form, 15%) !important;
      }
      box-shadow: inset 0 -1px $red_form !important;
    }
    .input-group-addon { border: 1px solid $red_form !important; }
  }
  .has-success {
    .form-control:not(textarea) {
      &:focus {
        box-shadow: inset 0 -1px lighten($green_form, 15%) !important;
      }
      box-shadow: inset 0 -1px $green_form !important;
    }
    .input-group-addon { border: 1px solid $green_form !important; }
  }
  .has-warning {
    .form-control:not(textarea) {
      &:focus {
        box-shadow: inset 0 -1px lighten($orange_form, 15%) !important;
      }
      box-shadow: inset 0 -1px $orange_form !important;
    }
    .input-group-addon { border: 1px solid $orange_form !important; }
  }

  .input-group-addon:first-child {
    border-top-right-radius: 2px;
    border: 1px solid #ccc;
    + input {
      padding-left: 10px !important;
    }
  }
  .input-group-addon:not(:first-child) {
    border-top-left-radius: 2px;
    border-left: 1px solid #ccc;
  }
  .input-group-btn {
    &:first-child {
      button { border-top-right-radius: 2px; }
      + input { padding-left: 10px !important; }
    }
    &:last-child {
      button {
        border-top-left-radius: 2px;
        + button { border-top-left-radius: 0; }
      }
    }
  }
  .input-group-btn
  .input-group-addon:last-child {
    border-left: 1px solid #ccc;
    border-top-left-radius: 2px;
  }
  .modal-divider-wrapper {
    margin-top: 15px;
  }

  .PPModalCart .minicart-quantity {
    border-radius: 0;
  }

  .footer {
    textarea {
      background: #292929;
      border-color: #252525;
    }
    input, textarea {
      color: #ddd !important;
      box-shadow: inset 0 -1px #222;
    }
    form {
      .input-group .input-group-addon {
        background: #292929;
        border-color: #252525;
        color: #999;
      }
      .has-error   .input-group-addon { border-color: #a94442; }
      .has-success .input-group-addon { border-color: #3c763d; }
    }
  }
  .full-screen-auth .auth-block-wrapper .form-group input {
    color: #eee !important;
  }
}

.thick-borders {
  .form-control {
    border: 2px solid #ddd;
  }
  .input-group-addon {
    border-width: 2px !important;
    border-color: #ddd;
  }
  .input-daterange .input-group-addon {
    border-width: 2px 0 !important;
  }
  .input-group-btn {
    button {
      border: 2px solid #ddd;
    }
    &:first-child .btn {
      margin-right: -2px;
    }
    &:not(:first-child) .btn {
      right: 1px;
      margin-right: -1px;
    }
  }
  .bootstrap-select > .dropdown-toggle.btn-default,
  .select2-selection.select2-selection--single,
  .select2-selection.select2-selection--multiple,
  .select2-dropdown {
    border: 2px solid #ddd;
  }
  &.swag-input {
    input[type="text"],
    input[type="email"],
    input[type="search"],
    input[type="password"] {
      box-shadow: inset 0 -2px #ddd;
    }
    .has-error {
      .form-control {
        &:focus {
          box-shadow: inset 0 -2px lighten($red_form, 15%) !important;
        }
        box-shadow: inset 0 -2px $red_form !important;
      }
      .input-group-addon { border: 2px solid $red_form !important; }
    }
    .has-success {
      .form-control {
        &:focus {
          box-shadow: inset 0 -2px lighten($green_form, 15%) !important;
        }
        box-shadow: inset 0 -2px $green_form !important;
      }
      .input-group-addon { border: 2px solid $green_form !important; }
    }
    .has-warning {
      .form-control {
        &:focus {
          box-shadow: inset 0 -2px lighten($orange_form, 15%) !important;
        }
        box-shadow: inset 0 -2px $orange_form !important;
      }
      .input-group-addon { border: 2px solid $orange_form !important; }
    }
    .input-group-addon:not(:first-child) {
      border-left-color: #ddd;
    }
    .input-daterange .input-group-addon { border-width: 2px !important; }
    .bootstrap-select > button.dropdown-toggle.btn-default,
    .select2-selection.select2-selection--single,
    .select2-selection.select2-selection--multiple {
      box-shadow: inset 0 -2px #ddd;
    }
  }
  &.dark-theme,
  .dark-theme {
    .form-control {
      border: 2px solid #333;
    }
    .input-group-addon {
      border-color: #333;
    }
    .input-group-btn:first-child {
      left: -2px;
    }
    .input-group-btn:last-child {
      left: 2px;
    }
    .bootstrap-select > .dropdown-toggle.btn-default,
    .select2-selection.select2-selection--single,
    .select2-selection.select2-selection--multiple,
    .select2-dropdown {
      border: 2px solid #333;
    }
    &.swag-input {
      input[type="text"],
      input[type="email"],
      input[type="search"],
      input[type="password"] {
        box-shadow: inset 0 -2px #333;
      }
      .input-group-addon {
        border-color: #333;
      }
      .bootstrap-select > button.dropdown-toggle.btn-default,
      .select2-selection.select2-selection--single,
      .select2-selection.select2-selection--multiple {
        box-shadow: inset 0 -2px #333;
      }
    }
  }
}

.all-buttons-square {
  .button,
  .btn,
  .bootstrap-switch {
    border-radius: 0 !important;
  }
}

.all-buttons-round {
  .button,
  .btn,
  .bootstrap-switch {
    border-radius: 200px !important;
  }
}
